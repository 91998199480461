import React, {useRef} from 'react';
import {BsPlus} from 'react-icons/bs';
import {FiAnchor, FiFileText, FiMail, FiPhone} from 'react-icons/fi';
import {useOutletContext} from 'react-router-dom';
import classes from './Account.module.scss';
import Header from './Header/Header';
import * as actions from '../../../../store/actions';
import {connect, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {ClipLoader} from "react-spinners";
import {createGradient} from "../../../../shared/Constants"
import Backdrop from "../../../UI/Backdrop/Backdrop";
import AccountUpdateConfirmationPopup from "../Account/AccountUpdateConfirmationPopup/AccountUpdateConfirmationPopup";
import ChangePasswordPopup from "./ChangePasswordPopup/ChangePasswordPopup";
import BackgroundTop from '../../../../assets/images/createProfile/backgroundTop.png';
import BackgroundBottom from '../../../../assets/images/createProfile/backgroundBottom.png';
import ct from 'countries-and-timezones'
import ColorPicker from "./ColorPicker/ColorPicker";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {isValidPhoneNumber} from 'libphonenumber-js';
import {AiFillCloseCircle} from "react-icons/ai";
import {IoMdCheckmarkCircleOutline} from "react-icons/io";
import ThemeColorLockPopup from "./ThemeColorLockPopup/ThemeColorLockPopup";


const Account = (props) => {
  const outletContext = useOutletContext();

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [accountStatus, setAccountStatus] = React.useState('ACTIVE');
  const [updateConfirmPopupVisible, setUpdateConfirmPopupVisible] =
    React.useState(false);
  const [changePasswordPopupVisible, setChangePasswordPopupVisible] =
    React.useState(false);
  const [themeColorLockPopupVisible, setThemeColorLockPopupVisible] = React.useState(false);
  const [staticUsername, setStaticUsername] = React.useState('');
  const [colorPickerIsOpen, setColorPickerIsOpen] = React.useState(false);
  const [usernameIsExists, setUsernameIsExists] = React.useState(false);


  const colorSetOne = [
    '#CD3838',
    '#51BC4E',
    '#00BFFF',
    '#E556E0',
    '#03E5B5',
    '#6236FF',
    '#FFA07A',
    '#CCFF00',
  ];

  const colorSetTwo = [
    '#D9D9D9',
    '#A181F1',
    '#FF8C00',
    '#E301A0',
    '#87cfeb',
    '#A12DB4',
    '#E47676',
    '',
  ];

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await props.onGetAccountDetails();
      setEmail(data.email);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setPhoneNumber(data.phoneNumber);
      setUsername(data.userName);
      setStaticUsername(data.userName);
      setAccountStatus(data.status);
    }
    fetchData().then(r => (r))
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await props.onIsUserNameExist(username);
        if (username.length < 4) {
          setUsernameIsExists(true);
        } else {
          setUsernameIsExists(response);
        }

      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [username, props.onIsUserNameExist]);


  const onHandleChangeBackgroundColor = (newColor) => {
    props.onChangeThemeColor(newColor);
  };

  const onUpdateHandler = () => {
    if (formIsValid()) {
      const res = props.onUpdateAccount({
          firstName,
          lastName,
          phoneNumber,
          status: accountStatus,
          themeColor: props.color,
          userName: username
        },
        showToast,
        username === staticUsername,
      );
      setUpdateConfirmPopupVisible(false);
    } else {
      setUpdateConfirmPopupVisible(false);
    }
  };

  const getCountry = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const obj = ct.getCountryForTimezone(tz)
    return obj.id.toLowerCase();
  }

  const formIsValid = async () => {
    const firstNamePattern = /^[a-zA-Z]+$/
    const lastNamePattern = /^[a-zA-Z]+$/
    const usernamePattern = /^[a-zA-Z0-9]+$/

    if (firstName.trim() === '') {
      showToast('First name cannot be empty');
      return false;
    }
    if (!firstNamePattern.test(firstName.trim())) {
      showToast('First name should contain only letters');
      return false;
    }
    if (lastName.trim() === '') {
      showToast('Last name cannot be empty');
      return false;
    }
    if (!lastNamePattern.test(lastName.trim())) {
      showToast('Last name should contain only letters');
      return false;
    }
    if (username.trim() === '') {
      showToast('Username cannot be empty');
      return false;
    }
    if (!usernamePattern.test(username.trim())) {
      showToast('Username should not contain @, #, or spaces');
      return false;
    }
    if (phoneNumber.trim() === '') {
      showToast('Phone number cannot be empty');
      return false;
    }

    if (!isValidPhoneNumber(phoneNumber)) {
      showToast('Invalid phone number');
      return false;
    }

    return true;
  };


  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style: {background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 35)} 100%)`}
    });
  };

  const getTextColor = (bgColor) => {
    const luminance =
      0.2126 * parseInt(bgColor.substring(1, 3), 16) +
      0.7152 * parseInt(bgColor.substring(3, 5), 16) +
      0.0722 * parseInt(bgColor.substring(5, 7), 16);
    return luminance <= 0.7 ? "#ffffff" : "#000000";
  };

  const before = useSelector(state => state.auth.token)
  const after = useRef(before)

  React.useEffect(() => {
    if (after.current !== before) {
      onUpdateHandler();
    }

    after.current = before;
  }, [before]);

  const onGetPassword = (value) => {
    props.onSignIn("CommercialPrime", props.user, value, showToast);
  };

  const setCustomColor = (color) => {
    props.onChangeThemeColor(color);
  }

  const handlePhoneChange = (phone) => {
    setPhoneNumber(phone);
  };

  return (
    <div className={classes.Account}>
      <Header toggleMenu={outletContext.toggleMenu}/>
      {updateConfirmPopupVisible ? (
        <Backdrop onClick={() => setUpdateConfirmPopupVisible(false)}>
          <AccountUpdateConfirmationPopup
            color={props.color}
            onClose={() => {
              setUpdateConfirmPopupVisible(false)
            }
            }
            getPassword={onGetPassword}
          />
        </Backdrop>
      ) : null}
      {changePasswordPopupVisible ? (
        <Backdrop onClick={() => setChangePasswordPopupVisible(false)}>
          <ChangePasswordPopup
            color={props.color}
            onClose={() => {
              setChangePasswordPopupVisible(false)
            }
            }
          />
        </Backdrop>
      ) : null}

      {themeColorLockPopupVisible ? (
        <Backdrop onClick={() => setThemeColorLockPopupVisible(false)}>
          <ThemeColorLockPopup
            color={props.color}
            onClose={() => {
              setThemeColorLockPopupVisible(false)
            }
            }
          />
        </Backdrop>
      ) : null}

      {colorPickerIsOpen ? (
        <Backdrop onClick={() => setColorPickerIsOpen(false)}>
          <ColorPicker
            onClose={() => setColorPickerIsOpen(false)}
            color={props.color}
            changeColor={setCustomColor}
          />
        </Backdrop>
      ) : null}
      <div style={{backgroundColor: props.color}} className={classes.LeftContainer}>
        <img src={BackgroundTop} alt="commercial prime"/>
        <img src={BackgroundBottom} alt="commercial prime"/>
        <p style={{color: getTextColor(props.color)}}>
          Configure your account
          <br/>
          details, so others will see
          <br/>
          the changes
        </p>
      </div>

      <div className={classes.RightContainer}>
        <div className={classes.DetailContainer}>
          <div className={classes.DetailRow}>
            <div className={classes.Detail}>
              <p className={classes.Label}>First Name</p>
              <div style={{background: props.color}} className={classes.InputWrapper}>
                <div className={classes.Input}>
                  <div className={classes.IconContainer}>
                    <FiAnchor size={16} color="#8d8d8d"/>
                  </div>
                  <input value={firstName} onChange={e => setFirstName(e.target.value)}/>
                </div>
              </div>
            </div>
            <div className={classes.Detail}>
              <p className={classes.Label}>Last Name</p>
              <div style={{background: props.color}} className={classes.InputWrapper}>
                <div className={classes.Input}>
                  <div className={classes.IconContainer}>
                    <FiAnchor size={16} color="#8d8d8d"/>
                  </div>
                  <input value={lastName} onChange={event => setLastName(event.target.value)}/>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.DetailRow}>
            <div className={classes.Detail}>
              <p className={classes.Label}>Username</p>
              <div style={{background: props.color}} className={classes.InputWrapper}>
                <div className={classes.Input}>
                  <div className={classes.IconContainer}>
                    <FiAnchor size={16} color="#8d8d8d"/>
                  </div>
                  <input value={username} onChange={event => setUsername(event.target.value)}/>
                  <div
                    className={classes.TickIcon}
                  >
                    {username.toLowerCase() === staticUsername.toLowerCase() ? null :
                      (usernameIsExists ?
                        <AiFillCloseCircle color={'#ff3b3b'}/>
                        : <IoMdCheckmarkCircleOutline color={'rgb(113 191 63)'}/>)
                    }

                  </div>
                </div>
              </div>
            </div>
            <div className={classes.Detail}>
              <p className={classes.Label}>Email Address</p>
              <div style={{background: props.color}} className={classes.InputWrapper}>
                <div className={classes.Input}>
                  <div className={classes.IconContainer}>
                    <FiAnchor size={16} color="#8d8d8d"/>
                  </div>
                  <input value={email} disabled={true} onChange={event => setEmail(event.target.value)}/>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.DetailRow}>
            <div className={classes.Detail}>
              <p className={classes.Label}>Phone Number</p>
              <div style={{background: props.color}} className={classes.InputWrapper}>
                <div className={classes.Input}>
                  <PhoneInput
                    country={getCountry()}
                    countryCodeEditable={false}
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    enableSearch={true}
                    disableSearchIcon={true}
                    inputClass={classes.PhoneInput}
                    buttonClass={classes.ButtonClass}
                    dropdownClass={classes.DropdownClass}
                    containerClass={classes.ContainerClass}
                    searchClass={classes.SearchClass}
                  />
                </div>
              </div>
            </div>
            <div className={classes.Detail}>
              <p className={classes.Label}>Account Status</p>
              <div style={{background: props.color}} className={classes.InputWrapper}>
                <div className={classes.Input}>
                  <p
                    className={classes.AccountStatus}>{accountStatus.charAt(0) + accountStatus.slice(1).toLowerCase()}</p>
                  {/* <div className={classes.StatusSwitch}>
                    <div className={classes.StatusNob}></div>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.MobileDetailContainer}>
          <div style={{background: props.color,}} className={classes.MobileInputWrapper}>
            <div className={classes.MobileInput}>
              <div className={classes.IconContainer}>
                <FiAnchor size={16} color="#8d8d8d"/>
              </div>
              <input placeholder="First Name" value={firstName} onChange={event => setFirstName(event.target.value)}/>
            </div>
          </div>
          <div style={{background: props.color}} className={classes.MobileInputWrapper}>
            <div className={classes.MobileInput}>
              <div className={classes.IconContainer}>
                <FiAnchor size={16} color="#8d8d8d"/>
              </div>
              <input placeholder="Last Name" value={lastName} onChange={event => setLastName(event.target.value)}/>
            </div>
          </div>
          <div style={{background: props.color}} className={classes.MobileInputWrapper}>
            <div className={classes.MobileInput}>
              <div className={classes.IconContainer}>
                <FiAnchor size={16} color="#8d8d8d"/>
              </div>
              <input placeholder="User Name" value={username} onChange={event => setUsername(event.target.value)}/>
            </div>
          </div>
          <div style={{background: props.color}} className={classes.MobileInputWrapper}>
            <div className={classes.MobileInput}>
              <div className={classes.IconContainer}>
                <FiMail size={16} color="#8d8d8d"/>
              </div>
              <input placeholder="Email Address" disabled={true} value={email}
                     onChange={event => setEmail(event.target.value)}/>
            </div>
          </div>
          <div style={{background: props.color}} className={classes.MobileInputWrapper}>
            <div className={classes.MobileInput}>
              <PhoneInput
                country={getCountry()}
                //countryCodeEditable={false}
                value={phoneNumber}
                onChange={handlePhoneChange}
                enableSearch={true}
                disableSearchIcon={true}
                inputClass={classes.PhoneInput}
                buttonClass={classes.ButtonClass}
                dropdownClass={classes.DropdownClass}
                searchClass={classes.SearchClass}
              />
            </div>
          </div>
          <div style={{background: props.color}} className={classes.MobileInputWrapper}>
            <div className={classes.MobileInput}>
              <div className={classes.MobileAccountStatusLabelContainer}>
                <div className={classes.IconContainer}>
                  <FiFileText size={16} color="#8d8d8d"/>
                </div>
                <p>Account Status</p>
              </div>
              <div className={classes.MobileAccountStatusContainer}>
                <p
                  className={classes.MobileAccountStatus}>{accountStatus.charAt(0) + accountStatus.slice(1).toLowerCase()}</p>
                {/*<div className={classes.StatusSwitch}>
                  <div className={classes.StatusNob}></div>
                </div>*/}
              </div>
            </div>
          </div>
        </div>

        <div className={classes.PaletteContainer}>
          <div className={classes.DefaultColor}>
            <p>Account Theme</p>
            <p
              onClick={() => {
                if (props.companyExits) {
                  setThemeColorLockPopupVisible(true);
                } else {
                  onHandleChangeBackgroundColor("#DBB659");
                }
              }}
            >Reset color
            </p>
          </div>
          <div className={classes.ColorPalette}>
            {[...colorSetOne, ...colorSetTwo].map((color, index) =>
              color === '' ? (
                <div onClick={() => {
                  if (props.companyExits) {
                    setThemeColorLockPopupVisible(true)
                  } else {
                    setColorPickerIsOpen(true)
                  }
                }} className={classes.ColorPlus} key={`plus-${index}`}>
                  <BsPlus size={16} color="white"/>
                </div>
              ) : (
                <div
                  className={classes.Color}
                  style={{backgroundColor: color}}
                  key={`color-${index}`}
                  onClick={() => {
                    if (props.companyExits) {
                      setThemeColorLockPopupVisible(true)
                    } else {
                      onHandleChangeBackgroundColor(color)
                    }
                  }}
                />
              ),
            )}
          </div>
        </div>

        <div style={{background: props.color}} className={classes.MobilePaletteContainer}>
          <div className={classes.MobilePalette}>
            <div className={classes.DefaultColorSection}>
              <p>Account Theme</p>
              <p
                onClick={() => {
                  if (props.companyExits) {
                    setThemeColorLockPopupVisible(true);
                  } else {
                    onHandleChangeBackgroundColor('#DBB659');
                  }
                }}
              >
                Reset color
              </p>
            </div>
            <div className={classes.MobileColorPalette}>
              <div>
                {colorSetOne.map((color, index) =>
                  color === '' ? (
                    <div key={`color-plus-${index}`}
                         className={classes.ColorPlus}
                         onClick={() => {
                           if (props.companyExits) {
                             setThemeColorLockPopupVisible(true)
                           } else {
                             setColorPickerIsOpen(true)
                           }
                         }}
                    >
                      <BsPlus size={16} color="white"/>
                    </div>
                  ) : (
                    <div
                      key={`color-${color}-${index}`}
                      className={classes.Color}
                      style={{backgroundColor: color}}
                      onClick={() => {
                        if (props.companyExits) {
                          setThemeColorLockPopupVisible(true);
                        } else {
                          onHandleChangeBackgroundColor(color);
                        }
                      }}
                    />
                  )
                )}

              </div>
              <div>
                {colorSetTwo.map((color, index) =>
                  color === '' ? (
                    <div key={`color-plus-${index}`} className={classes.ColorPlus} onClick={() => {
                      if (props.companyExits) {
                        setThemeColorLockPopupVisible(true)
                      } else {
                        setColorPickerIsOpen(true)
                      }
                    }}>
                      <BsPlus size={16} color="white"/>
                    </div>
                  ) : (
                    <div
                      key={`color-${color}-${index}`}
                      className={classes.Color}
                      style={{backgroundColor: color}}
                      onClick={() => {
                        if (props.companyExits) {
                          setThemeColorLockPopupVisible(true);
                        } else {
                          onHandleChangeBackgroundColor(color);
                        }
                      }}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={classes.BottomButtonContainer}>
          <button
            className={classes.ChangePasswordBtn}
            style={{borderColor: props.color}}
            onClick={() => setChangePasswordPopupVisible(true)}
          >
            Change Password
          <
            /button>
          <button
            style={{
              background:
                `linear-gradient(91.3deg, 
                ${props.color} 
                1.61%, 
                ${createGradient(props.color, 50)} 
                100%)`
            }}
            className={classes.UpdateDetailsBtn}
            //onClick={onUpdateHandler}
            onClick={() => {
              formIsValid().then((isFormValid) => {
                setUpdateConfirmPopupVisible(isFormValid)
              });
            }
            }
          >
            {props.loading === true ? (
              <div className={classes.SpinnerContainer}>
                <ClipLoader color={'#dbb659'} loading={true} size={22}/>
              </div>
            ) : (
              <p>Update Details</p>
            )
            }</button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    color: state.home.themeColor,
    loading: state.account.loading,
    token: state.auth.token,
    user: state.auth.user,
    companyExits: state.home.companyExits,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAccountDetails: () => dispatch(actions.getAccountDetails()),
    onUpdateAccount: (accountDetails, showToast, logoutStatus) => dispatch(actions.updateAccountDetails(accountDetails, showToast, logoutStatus)),
    onChangeThemeColor: color => dispatch(actions.changeThemeColor(color)),
    onSignIn: (tenantName, username, password, showToast) =>
      dispatch(actions.signIn(tenantName, username, password, showToast)),
    onSignOut: () => dispatch(actions.signOut()),
    onIsUserNameExist: username => dispatch(actions.isUserNameExist(username)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
