import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(config => {
  const authData = JSON.parse(localStorage.getItem('prime'));

  if (authData) {
    config.headers['Authorization'] = `Bearer ${authData.token}`;
  }
  return config;
});

export default axiosInstance;
