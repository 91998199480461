import * as actionTypes from './actionTypes';
import axios from "../../../axios";

const getLink = link =>{
    return{
        type:actionTypes.GET_LINK_BY_USERNAME,
        link,
    }
}

export const getLinkByUsername = username =>{
    return dispatch =>{
        axios
            .get(`/api/product-service/get-link-by-userName?userName=${username}`)
            .then(response =>{
                const filteredLinks = response.data.map(item => item.link).filter(link => link);
                dispatch(getLink(filteredLinks));
            })
            .catch((e=>console.log(e)));
    }
}
