import axios from '../../../axios';
import * as actionTypes from './actionTypes';

export const getCompanySettings = (showToast) => {
  return dispatch => {
    dispatch({
      type: actionTypes.GET_COMPANY_SETTING_START,
    });
    axios
      .get('api/company-service/get-company-settings')
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_COMPANY_SETTING_SUCCESS,
            payload: res.data,
          });
        } else if (res.status === 204) {
        }
      })
      .catch((e) => {
        showToast('Something went wrong!');
        console.log(e)
      }).finally(() => {
      dispatch({
        type: actionTypes.GET_COMPANY_SETTING_END,
      });
    });
  };
};

export const setCompanySettings = (data, showToast) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_COMPANY_SETTING_START
    });
    axios
      .patch('api/company-service/set-company-settings', data)
      .then((res) => {
        console.log(res.status)
        if (res.status === 200) {
          dispatch({
            type: actionTypes.SET_COMPANY_SETTING_SUCCESS,
            payload: res.data,
          });

          // dispatch(updateAccountEnd());
          showToast('Setting updated successfully!');
          // if (!logoutStatus) {
          // setTimeout(() => {
          // dispatch(signOut());
          // }, 1500);
          // }
        }
      })
      .catch((e) => {
        console.log(e)
        // if (e.response.data.errorCode === 'USERNAME_ALREADY_EXISTS') {
        //     dispatch(updateAccountEnd())
        //     showToast('Username already exists!');
        //     return false;
        // }
        // showToast('Something went wrong!');
      }).finally(() => {
      dispatch({
        type: actionTypes.SET_COMPANY_SETTING_END,
      });
    });
  };
};