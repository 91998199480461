import React from 'react';
import classes from './ProfileCard.module.scss';
import {FaUser} from 'react-icons/fa';
import {FiChevronRight} from 'react-icons/fi';
import {adjustColorBrightness, createGradient} from "../../../shared/Constants";

const ProfileCard = props => {
  return (
    <div style={{background:createGradient(adjustColorBrightness(props.color,90),40)}} className={classes.ProfileCard}>
      <div
        className={props.companyExits ? null : classes.BackgroundImage }
      >
        {props.hidePreviewButton ? null : (
          <div
            className={classes.PreviewButton}
            onClick={props.showConfirmation}
          >
            <FaUser size={12} color="#C9C9C9" />
            <p>Preview Profile</p>
            <FiChevronRight size={14} color="#C9C9C9" />
          </div>
        )}
        <div className={classes.ProfileContainer}>
          <div style={{color:props.color}} className={classes.ProfileInfoContainer}>
            <h2>
              {props.fullName.trim() === '' ? 'Full Name' : props.fullName}
            </h2>
            <p>{props.title.trim() === '' ? 'Title' : props.title}</p>
            <span>
              {props.company.trim() === '' ? 'Company' : props.company}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
