import * as actionTypes from '../actions/home/actionTypes';

const initialState = {
  loading: false,
  vcardStatus: null,
  profiles: [],
  changingVcardStatus: false,
  themeColor: '#DBB659',
  logo: '',
  collaboration: false,
  profileLimit: null,
  companyExits: false,
  privacyMode: false,
  tenantEnabledPrivacyMode:false,
  companyName:'Commercial Prime'
};


const changeThemeColor = (state, action) => {
  return {...state, themeColor: action.themeColor}
}

const getAllProfilesStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const getAllProfilesSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    ...action.payload,
  };
};

const getAllProfilesFail = (state, action) => {
  return {
    ...state,
    loading: false,
  };
};


const changeVcardStatusStart = (state, action) => {
  return {
    ...state,
    changingVcardStatus: true,
  };
};

const changeVcardStatusSuccess = (state, action) => {
  return {
    ...state,
    changingVcardStatus: false,
    ...action.payload,
  };
};

const changeVcardStatusFail = (state, action) => {
  return {
    ...state,
    changingVcardStatus: false,
  };
};

const changePrivacyModeSuccess = (state, action) => {
  return{
    ...state,
    privacyMode: action.payload.privacyMode,
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PROFILES_START:
      return getAllProfilesStart(state, action);
    case actionTypes.GET_ALL_PROFILES_SUCCESS:
      return getAllProfilesSuccess(state, action);
    case actionTypes.GET_ALL_PROFILES_FAIL:
      return getAllProfilesFail(state, action);
    case actionTypes.CHANGE_VCARD_STATUS_START:
      return changeVcardStatusStart(state, action);
    case actionTypes.CHANGE_VCARD_STATUS_SUCCESS:
      return changeVcardStatusSuccess(state, action);
    case actionTypes.CHANGE_VCARD_STATUS_FAIL:
      return changeVcardStatusFail(state, action);
    case actionTypes.CHANGE_THEME_COLOR:
      return changeThemeColor(state, action)
    case actionTypes.CHANGE_PRIVACY_MODE_SUCCESS:
      return changePrivacyModeSuccess(state, action)
    default:
      return state;
  }
};

export default reducer;
