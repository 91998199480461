import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import classes from './App.module.scss';

import CreateProfilePage from './pages/CreateProfilePage/CreateProfilePage';
import Login from './pages/LoginPage/LoginPage';
import Register from './pages/RegisterPage/RegisterPage';
import Dashboard from './pages/DashboardPage/DashboardPage';
import {connect} from 'react-redux';
import Home from './components/Dashboard/DashboardBody/Home/Home';
import Contacts from './components/Dashboard/DashboardBody/Contacts/Contacts';
import ShareableLink from './components/Dashboard/DashboardBody/ShareableLink/ShareableLink';
import Account from './components/Dashboard/DashboardBody/Account/Account';
import Analytics from "./components/Dashboard/DashboardBody/Analytics/Analytics";
import ForgotPassword from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import Teams from "./components/Dashboard/DashboardBody/Teams/Teams";
import Users from "./components/Dashboard/DashboardBody/Teams/Body/Users/Users";
import Profiles from "./components/Dashboard/DashboardBody/Teams/Body/Profiles/Profiles";
import CompanyDetails from "./components/Dashboard/DashboardBody/Teams/Body/CompanyDetails/CompanyDetails";
import Branding from "./components/Dashboard/DashboardBody/Teams/Body/Branding/Branding";
import Settings from './components/Dashboard/DashboardBody/Teams/Body/Setttings/Settings';
import {signOut, tryAutoSignIn} from "./store/actions";
import {ToastContainer} from "react-toastify";

const google = window.google;

const App = props => {
  React.useEffect(() => {
    const SCOPES = process.env.REACT_APP_GOOGLE_SCOPES;

    const gapiLoaded = () => {
      window.gapi.load('client', intializeGapiClient);
    };

    const intializeGapiClient = () => {
      window.gapi.client.init({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        discoveryDocs: [
          process.env.REACT_APP_GOOGLE_DISCOVERY_DOCS,
        ],
      });
    };

    const gisLoaded = () => {
      window.tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id:
        process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: SCOPES,
        callback: '', // defined later
      });
    };

    const script1 = document.createElement('script');
    script1.src = 'https://apis.google.com/js/api.js';
    script1.async = true;
    script1.defer = true;
    script1.onload = gapiLoaded;
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.src = 'https://accounts.google.com/gsi/client';
    script2.async = true;
    script2.defer = true;
    script2.onload = gisLoaded;
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  });

  React.useEffect(() => {
    //const twoDaysAgo = Date.now() - 5000;
    const twoDaysAgo = Date.now() - 2 * 24 * 60 * 60 * 1000;

    if (localStorage.getItem('lastActive') < twoDaysAgo) {
      props.onSignOut();
    }

    props.onTryAutoSignin();
  });

  React.useEffect(() => {
    const setLastActive = () => {
      localStorage.setItem('lastActive', Date.now());
    };

    const handleBeforeUnload = () => {
      setLastActive();
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        setLastActive();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    setLastActive();

    return () => {
      // Remove event listeners when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <div className={classes.App}>
      <ToastContainer/>
      <Routes>
        {!props.token ? (
          <>
            <Route path="/" element={<Login/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/reset-password" element={<ForgotPassword/>}/>
            <Route path="*" element={<Navigate to="/" replace/>}/>
          </>
        ) : (
          <>
            <Route path="/home" element={<Dashboard/>}>
              <Route path="/home" element={<Home/>}/>
              <Route path="/home/analytics" element={<Analytics/>}/>
              <Route path="/home/contacts" element={<Contacts/>}/>
              <Route path="/home/link" element={<ShareableLink/>}/>
              <Route path="/home/account" element={<Account/>}/>
              {props.role === "ROLE_COMPANY_ADMIN" &&
                <Route path="/home/teams" element={<Teams/>}>
                  <Route path="/home/teams" element={<Users/>}/>
                  <Route path="/home/teams/profiles" element={<Profiles/>}/>
                  <Route path="/home/teams/create-profile" element={<CreateProfilePage/>}/>
                  <Route path="/home/teams/company-details" element={<CompanyDetails/>}/>
                  <Route path="/home/teams/branding" element={<Branding/>}/>
                  <Route path="/home/teams/settings" element={<Settings/>}/>
                </Route>}
            </Route>
            <Route path="/create-profile" element={<CreateProfilePage/>}/>
            <Route path="*" element={<Navigate to="/home" replace/>}/>
          </>
        )}
      </Routes>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.userRole,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignin: () => dispatch(tryAutoSignIn()),
    onSignOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
