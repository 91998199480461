import React, {useEffect, useState} from 'react';
import classes from './CreateProfile.module.scss';
import {RiArrowLeftSLine} from 'react-icons/ri';
import ProfileCard from './ProfileCard/ProfileCard';
import {IconPickerItem} from 'react-fa-icon-picker';
import UploadImage from '../../assets/images/upload.png';
import Logo from '../../assets/images/commercial prime-33.svg';
import BackgroundTop from '../../assets/images/createProfile/backgroundTop.png';
import BackgroundBottom from '../../assets/images/createProfile/backgroundBottom.png';
import {IoImageOutline} from 'react-icons/io5';
import {useLocation, useNavigate} from 'react-router-dom';
import AddFieldModal from './AddFieldModal/AddFieldModal';
import {connect} from 'react-redux';
import VisibilityDropdown from './VisibilityDropdown/VisibilityDropdown';
import * as actions from '../../store/actions';
import {useDropzone} from 'react-dropzone';
import {toast, ToastContainer} from 'react-toastify';
import {adjustColorBrightness, createGradient, VISIBILITY} from '../../shared/Constants';
import PreviewConfirmation from './PreviewConfirmation/PreviewConfirmation';
import Backdrop from '../UI/Backdrop/Backdrop';
import DeleteConfirmation from './DeleteConfirmation/DeleteConfirmation';
import ExitConfirmation from './ExitConfirmation/ExitConfirmation';
import {FiTrash} from 'react-icons/fi';
import imageCompression from 'browser-image-compression';
import ImageCropper from './ImageCropper/ImageCropper';
import {MdOutlineClose} from 'react-icons/md';
import {DarkModeSwitch} from 'react-toggle-dark-mode';
import PhoneInput from "react-phone-input-2";
import ct from "countries-and-timezones";
import {Tooltip} from "antd";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CreateProfile = props => {

  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState('');

  const [previewConfirmationVisible, setPreviewConfirmationVisibility] =
    React.useState(false);

  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    React.useState(false);

  const [exitConfirmationVisible, setExitConfirmationVisibility] =
    React.useState(false);

  const [cropperVisible, setCropperVisibility] = React.useState(false);

  const [previewConfirmationMessage, setPreviewConfirmationMessage] =
    React.useState('');

  const [adjustColor, setAdjustColor] = React.useState('');

  const [saveButtonIsClicked, setSaveButtonIsClicked] = React.useState(false);
  const [isReadOnly, setIsReadOnly] = React.useState(props.userRole === 'ROLE_CORPORATE_USER');

  const onDrop = React.useCallback(acceptedFiles => {
    setImage(acceptedFiles[0]);
  }, []);

  React.useEffect(
    () => {
      props.onClearData();
      setAdjustColor(adjustColorBrightness(props.color, 80));
    },
    [],
  );

  useEffect(() => {
    props.getCompanySettings(showToast);
  }, [])

  useEffect(() => {
    if (props.companySettings) {
      setIsReadOnly((props.userRole === 'ROLE_CORPORATE_USER' || props.userRole === 'ROLE_PLATFORM_USER') && !props.companySettings.enableProfileSelfUpdate);
    }
  }, [props.companySettings])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const firstName = props.leftProfileFields.find(
    field => field.name === 'First Name',
  );
  const lastName = props.leftProfileFields.find(
    field => field.name === 'Last Name',
  );
  const contactNumber = props.leftProfileFields.find(
    field => field.name === 'Contact Number',
  );
  const title = props.leftProfileFields.find(field => field.name === 'Title');
  const company = props.leftProfileFields.find(
    field => field.name === 'Company',
  );

  const getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = '';

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  //test access-key = AKIAWPEV6TSFLKD53GP5
  //test secret-key = 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
  //test bucket = testfilestore.commercialprime.com

  const getCountry = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const obj = ct.getCountryForTimezone(tz)
    return obj.id.toLowerCase();
  }

  const getTempUrl = (link) => {
    const AWS = require('aws-sdk');

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const s3 = new AWS.S3({
      endpoint: process.env.REACT_APP_AWS_ENDPOINT,
    });

    const key = link.split('/').pop();

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Key: key,
      Expires: 60,
      ResponseContentDisposition: 'inline'
    };

    return s3.getSignedUrl('getObject', params);
  }

  const setImage = file => {
    if (file.type.split('/')[0] !== 'image') {
      showToast('Select only an image ');
    } else {
      //props.onSaveProfilePicture(file);
      imageCompression(file, {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      })
        .then(compressedImage => {

          // props.onUploadProfilePicture(compressedImage)
          return getBase64(compressedImage);
        })
        .then(result => {
          props.onChangeProfilePicture(result);
          setCropperVisibility(true);
        })
        .catch(() => console.log('Image select error'));
    }
  };

  const onFileSelectHandler = () => {
    const callback = event => {
      setImage(event.target.files[0]);
    };

    document.getElementById('inpProfilePicture').oninput = callback;
    document.getElementById('inpProfilePicture').click();
  };

  const formIsValid = () => {
    if (props.profileName.trim() === '') {
      showToast('Profile name cannot be empty');
      return false;
    } else if (firstName.value.trim() === '') {
      showToast('First name cannot be empty');
      return false;
    } else if (lastName.value.trim() === '') {
      showToast('Last name cannot be empty');
      return false;
    } else if (contactNumber.value.trim() === '') {
      showToast('Contact number cannot be empty');
      return false;
    } else {
      return true;
    }
  };

  let result = '';

  const onSaveHandler = async successCallback => {
    if (formIsValid()) {
      console.log("Form submit tried")

      if (props.profilePicture.value.includes(';base64')) {
        //convert base64 image to file
        const base64String = props.profilePicture.value;
        const byteCharacters = atob(base64String.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'image/jpeg'});
        const file = new File([blob], 'image.jpg', {type: 'image/jpeg'});

        result = await props.onUploadProfilePicture(file);
      } else {
        result = await props.profilePicture.value;
      }

      console.log(`Result = ${result}`)

      await props.onChangeProfilePicture(result);

      const data = [];
      data.push({
        id: props.profilePicture.id,
        name: props.profilePicture.name,
        icon: props.profilePicture.icon,
        type: 'STRING',
        value: result,
        publicVisible:
          props.profilePicture.visibility === VISIBILITY.PUBLIC ? true : false,
      });

      props.leftProfileFields.forEach(field => {
        data.push({
          id: field.id,
          name: field.name,
          icon: field.icon,
          type: 'STRING',
          value: field.value,
          publicVisible: field.visibility === VISIBILITY.PUBLIC ? true : false,
        });
      });

      props.rightProfileFields.forEach(field => {
        data.push({
          id: field.id,
          name: field.name,
          icon: field.icon,
          type: 'STRING',
          value: field.value,
          publicVisible: field.visibility === VISIBILITY.PUBLIC ? true : false,
        });
      });

      props.additionalProfileFields.forEach(field => {
        data.push({
          id: field.id,
          name: field.name,
          icon: field.icon,
          type: 'STRING',
          value: field.value,
          publicVisible: field.visibility === VISIBILITY.PUBLIC ? true : false,
        });
      });

      const requestData = {
        id: query.get('create') ? +query.get('create') : (query.get('edit') ? +query.get('edit') : null),
        name: props.profileName,
        data,
        darkMode: props.darkMode,
      };
      setSaveButtonIsClicked(true);
      if (query.get('create')) {
        props.onCreateTeamProfile(requestData, successCallback);
      } else if (query.get('edit')) {
        props.onUpdateProfile(requestData, successCallback);
      } else {
        props.onSaveProfile(requestData, successCallback);
      }
    }
  };

  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style: {background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 35)} 100%)`}
    });
  };

  //Input Change Handlers

  const onChangeLeftProfileFieldHandler = (value, index) => {
    const updatedField = {...props.leftProfileFields[index]};
    updatedField.value = value;

    props.onChangeLeftProfileField(updatedField, index);
  };

  const onChangeRightProfileFieldHandler = (value, index) => {
    const updatedField = {...props.rightProfileFields[index]};
    updatedField.value = value;

    props.onChangeRightProfileField(updatedField, index);
  };

  const onChangeAdditionalProfileFieldHandler = (value, index) => {
    const updatedField = {...props.additionalProfileFields[index]};
    updatedField.value = value;

    props.onChangeAdditionalProfileField(updatedField, index);
  };

  const onRemoveAdditionalProfileFieldHandler = fieldIndex => {
    props.onRemoveAdditionalProfileField(fieldIndex);
  };

  //Visibility Change Handlers

  const onChangeLeftFieldVisibilityHandler = (value, index) => {
    const updatedField = {...props.leftProfileFields[index]};
    updatedField.visibility = value;

    props.onChangeLeftProfileField(updatedField, index);
  };
  const onChangeRightFieldVisibilityHandler = (value, index) => {
    const updatedField = {...props.rightProfileFields[index]};
    updatedField.visibility = value;

    props.onChangeRightProfileField(updatedField, index);
  };
  const onChangeAdditionalFieldVisibilityHandler = (value, index) => {
    const updatedField = {...props.additionalProfileFields[index]};
    updatedField.visibility = value;

    props.onChangeAdditionalProfileField(updatedField, index);
  };

  const onPreviewConfirmationHandler = () => {
    onSaveHandler(() => {
      setPreviewConfirmationVisibility(false);
      props.onChangeActiveProfile(
        +query.get('edit'),
        null,
        () => {
          window.open(
            process.env.REACT_APP_SHARABLE_LINK + '@' + props.link[0],
            '_blank',
          );
        },
      );
    });
  };

  const onDeleteHandler = successCallback => {
    props.onDeleteProfile(
      query.get('edit') ? +query.get('edit') : null,
      successCallback,
      showToast,
    );
    setDeleteConfirmationVisibility(false);
    props.onClearData();
  };

  const onDeleteConfirmationHandler = () => {
    const totalProfileCount = props.profiles.length;

    if (totalProfileCount > 1) {
      const toActiveProfile = props.profiles.find(profile => profile.active);
      if (query.get('edit') === toActiveProfile.id.toString()) {
        const activeProfile = props.profiles.find(profile => !profile.active);
        props.onChangeActiveProfile(activeProfile.id, null, () => {
          onDeleteHandler(() => {
            showToast("Successfully deleted!");
            setTimeout(() => navigate('/home'), 1000);
          });
        });
      } else {
        onDeleteHandler(() => {
          showToast("Successfully deleted!");
          setTimeout(() => navigate('/home'), 1000);
        });
      }
    } else {
      onDeleteHandler();
    }
  };

  React.useEffect(() => {
    return () => {
      setSaveButtonIsClicked(false)
    };
  }, []);

  const linkChange = (e, index) => {

    if (e.keyCode === 8) {
      onChangeRightProfileFieldHandler(e.target.value, index)
    } else {
      const value = e.target.value.trim();
      const isCompleteURL = value.startsWith('http://') || value.startsWith('https://');

      const isBackspaceKeyPressed = e.nativeEvent.inputType === 'deleteContentBackward';
      if (isBackspaceKeyPressed && !value) {
        onChangeRightProfileFieldHandler('', index);
      } else {
        let updatedValue = value;

        if (!isCompleteURL) {
          updatedValue = `https://www.facebook.com/${value}`;
        }

        onChangeRightProfileFieldHandler(updatedValue, index);
      }
    }
  }
  
  return (
    <div className={classes.CreateProfile}>
      {previewConfirmationVisible ? (
        <Backdrop onClick={() => setPreviewConfirmationVisibility(false)}>
          <PreviewConfirmation
            color={props.color}
            onClose={() => setPreviewConfirmationVisibility(false)}
            onConfirmation={onPreviewConfirmationHandler}
            confirmationMessage={previewConfirmationMessage}
          />
        </Backdrop>
      ) : null}

      {deleteConfirmationVisible ? (
        <Backdrop onClick={() => setDeleteConfirmationVisibility(false)}>
          <DeleteConfirmation
            color={props.color}
            onClose={() => setDeleteConfirmationVisibility(false)}
            onConfirmation={onDeleteConfirmationHandler}
          />
        </Backdrop>
      ) : null}

      {exitConfirmationVisible ? (
        <Backdrop onClick={() => setExitConfirmationVisibility(false)}>
          <ExitConfirmation
            color={props.color}
            onClose={() => setExitConfirmationVisibility(false)}
            onConfirmation={() => {
              if (location.pathname === '/home/teams/create-profile') {
                navigate('/home/teams/profiles');
              } else {
                navigate('/home');
              }
            }}
          />
        </Backdrop>
      ) : null}

      {cropperVisible ? (
        <Backdrop onClick={() => setCropperVisibility(false)}>
          <ImageCropper onClose={() => setCropperVisibility(false)}/>
        </Backdrop>
      ) : null}

      <input type="file" id="inpProfilePicture" style={{display: 'none'}}/>

      <div className={classes.Header}>
        <div
          className={classes.BackButton}
          onClick={() => {
            if (query.get('edit') && !isReadOnly) {
              setExitConfirmationVisibility(true);
            } else {
              navigate('/home');
            }
          }}
        >
          <RiArrowLeftSLine size={25}/>
        </div>
        <div className={classes.Title}>
          <span>{
            query.get('edit') && isReadOnly ? 'View' : query.get('edit') ? 'Edit' : 'Create'

          } Profile</span>
        </div>

        {(!isReadOnly) &&
          <div>
            <div>
              <button
                style={{background: `linear-gradient(91.3deg, ${props.color} 1.61%, ${createGradient(props.color, 50)} 100%)`}}
                className={classes.SaveButton}
                disabled={saveButtonIsClicked}
                onClick={() =>
                  onSaveHandler(() => {
                    showToast('Successfully saved!');
                    if (location.pathname === '/home/teams/create-profile') {
                      setTimeout(() => navigate('/home/teams/profiles'), 1000);
                    } else {
                      setTimeout(() => navigate('/home'), 1000);
                    }
                    props.onClearData();
                  })
                }
              >
                Save
              </button>

              {query.get('edit') ? (
                <button
                  className={classes.DeleteButton}
                  onClick={() => setDeleteConfirmationVisibility(true)}
                >
                  Delete
                </button>
              ) : null}
            </div>
          </div>
        }


      </div>
      <ProfileCard
        fullName={`${firstName.value} ${lastName.value}`}
        title={title.value}
        company={company.value}
        hidePreviewButton={!query.get('edit')}
        color={props.color}
        companyExits={props.companyExits}
        showConfirmation={() => {
          const activeProfile = props.profiles.find(profile => profile.active);

          if (activeProfile.id === +query.get('edit')) {
            setPreviewConfirmationMessage(
              "The profile you're editing will be saved with the current changes",
            );
          } else {
            setPreviewConfirmationMessage(
              "The profile you're editing will be saved and set as the active profile",
            );
          }
          setPreviewConfirmationVisibility(true);
        }}
      />
      <div className={classes.Body}>
        <div style={{backgroundColor: props.color}} className={classes.TopContainer}>
          <img
            src={Logo}
            alt="Commercial Prime"/>
          <img src={BackgroundTop} alt="Commercial Prime"/>
          <img src={BackgroundBottom} alt="Commercial Prime"/>
        </div>

        <div className={classes.CreateProfileFormContainer}>
          <div className={classes.CreateProfileForm}>
            <div
              className={classes.DropArea}
              {...(!isReadOnly ? getRootProps() : [])}
              style={{borderColor: isDragActive ? '#dbb659' : '#424242', cursor: isReadOnly ? "no-drop" : "pointer"}}
            >
              {!isReadOnly && <input {...getInputProps()}/>}

              <div>
                <div className={classes.ImageContainer}>
                  {props.profilePicture.value === '' ? null : (
                    <div
                      className={classes.ClearButton}
                      onClick={e => {
                        if (!isReadOnly) {
                          props.onChangeProfilePicture('');
                          result = ''
                          e.stopPropagation();
                        }
                      }}
                    >
                      <MdOutlineClose size={16} color="#C5C5C5"/>
                    </div>
                  )}
                  <img
                    src={
                      props.profilePicture.value === ''
                        ? UploadImage
                        : props.profilePicture.value === null
                          ? ''
                          : props.profilePicture.value.includes(';base64')
                            ? props.profilePicture.value
                            : getTempUrl(props.profilePicture.value)
                    }
                    alt="Upload"
                  />
                </div>
                {!isReadOnly && <div style={{marginLeft: "32px"}}>
                  <p>
                    Drag & drop or click to select
                    <br/>
                    your profile picture
                  </p>

                  {/* <p>Drag & drop your profile picture here or</p> */}
                  {/* <button>Browse Files</button> */}
                </div>}
              </div>
            </div>

            <div className={classes.ProfilePictureContainer}>
              <div
                style={{background: `linear-gradient(91.3deg, ${adjustColor} 1.61%, ${createGradient(adjustColor, 50)} 100%)`}}
                className={classes.ProfilePicture}>
                <div onClick={() => {
                  if (!isReadOnly) {
                    onFileSelectHandler()
                  }
                }}>
                  {props.profilePicture.value === '' ? null : (
                    <div
                      className={classes.ClearButton}
                      onClick={e => {
                        if (!isReadOnly) {
                          props.onChangeProfilePicture('');
                          e.stopPropagation();
                        }
                      }}
                    >
                      <MdOutlineClose size={16} color="#C5C5C5"/>
                    </div>
                  )}

                  {props.profilePicture.value === '' ? (
                    <IoImageOutline size={30} color="#929292"/>
                  ) : (
                    <img src={
                      props.profilePicture.value === null
                        ? ''
                        : props.profilePicture.value.includes(';base64')
                          ? props.profilePicture.value
                          : getTempUrl(props.profilePicture.value)
                    } alt="Upload"/>
                  )}
                </div>
              </div>
              <p>Profile Picture</p>
            </div>
            {!props.companyExits &&
              <div className={classes.ThemeButton}>
                <DarkModeSwitch
                  checked={props.darkMode}
                  onChange={() => {
                    showToast(props.darkMode ? "Shareable link’s light mode is on" : "Shareable link’s dark mode is on")
                    props.onChangeDarkTheme()
                  }}
                  size={25}
                  sunColor={"white"}
                />
              </div>
            }
            <div className={classes.InputContainer}>
              <div>
                {query.get('edit') ? null : (
                  <div
                    style={{background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor, 80)} 100%)`}}
                    className={classes.InputWrapper}>
                    <div>
                      <IconPickerItem
                        icon="FaFolderMinus"
                        color="#919191"
                        size={16}
                      />

                      <input
                        placeholder="Profile Name"
                        value={props.profileName}
                        onChange={e =>
                          props.onChangeProfileName(e.target.value)
                        }
                      />
                    </div>
                  </div>
                )}
                {props.leftProfileFields.map((field, index) => {
                  if (field.name === 'Description') {
                    return (
                      <div
                        className={classes.InputWrapper}
                        style={{
                          height: '100px',
                          background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor, 80)} 100%)`
                        }}
                        key={field.name}
                      >
                        <div
                          style={{
                            height: '98px',
                            alignItems: 'flex-start',
                            paddingTop: '18px',
                          }}
                        >
                          <IconPickerItem
                            icon={field.icon}
                            color="#919191"
                            size={16}
                            containerStyles={{marginTop: '1px'}}
                          />
                          <textarea
                            placeholder={field.name}
                            style={{
                              height: '80%',
                              display: 'flex',
                              alignItems: 'flex-start',
                              overflow: 'hidden',
                            }}
                            value={field.value}
                            onChange={e =>
                              onChangeLeftProfileFieldHandler(
                                e.target.value,
                                index,
                              )
                            }
                            disabled={isReadOnly}
                          />
                          <VisibilityDropdown
                            disabled={isReadOnly}
                            visibility={field.visibility}
                            color={props.color}
                            onChangeVisibility={value =>
                              onChangeLeftFieldVisibilityHandler(value, index)
                            }
                          />
                        </div>
                      </div>
                    );
                  } else if (field.name === 'Contact Number') {
                    return (
                      <div
                        style={{background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor, 80)} 100%)`}}
                        className={classes.InputWrapper}
                        key={field.name}>
                        <div>
                          <PhoneInput
                            country={getCountry()}
                            countryCodeEditable={false}
                            placeholder={field.name}
                            value={field.value}
                            onChange={(e) => {
                              onChangeLeftProfileFieldHandler(
                                e,
                                index,
                              )
                            }}
                            enableSearch={true}
                            disableSearchIcon={true}
                            inputClass={classes.PhoneInput}
                            buttonClass={classes.LeftButtonClass}
                            dropdownClass={classes.DropdownClass}
                            searchClass={classes.SearchClass}
                            disabled={isReadOnly}
                          />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor, 80)} 100%)`}}
                        className={classes.InputWrapper} key={field.name}>
                        <div>
                          <IconPickerItem
                            icon={field.icon}
                            color="#919191"
                            size={16}
                          />

                          <input
                            placeholder={field.name}
                            value={field.value}
                            inputMode={
                              field.name === 'Contact Number'
                                ? 'numeric'
                                : field.name === 'Email'
                                  ? 'email'
                                  : 'text'
                            }
                            onChange={e =>
                              onChangeLeftProfileFieldHandler(
                                field.name === 'Contact Number'
                                  ? e.target.value.replace(/[^0-9+-]/g, '')
                                  : e.target.value,

                                index,
                              )
                            }
                            disabled={isReadOnly}
                          />

                          {field.name === 'First Name' ||
                          field.name === 'Last Name' ||
                          field.name === 'Contact Number' ? null : (
                            <VisibilityDropdown
                              color={props.color}
                              visibility={field.visibility}
                              onChangeVisibility={value =>
                                onChangeLeftFieldVisibilityHandler(value, index)
                              }
                              disabled={isReadOnly}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              <div>
                {props.rightProfileFields.map((field, index) => {
                  if (field.name === 'Whatsapp') {
                    return (
                      <div
                        style={{background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor, 80)} 100%)`}}
                        className={classes.InputWrapper}
                        key={field.name}>
                        <div>
                          <IconPickerItem
                            icon={field.icon}
                            color="#919191"
                            size={16}
                          />

                          <div style={{width: '100%'}}>
                            <PhoneInput
                              country={getCountry()}
                              countryCodeEditable={false}
                              placeholder={field.name}
                              value={field.value}
                              onChange={(e) => {
                                onChangeRightProfileFieldHandler(
                                  e,
                                  index,
                                );
                              }}
                              enableSearch={true}
                              disableSearchIcon={true}
                              inputClass={classes.PhoneInput}
                              buttonClass={classes.RightButtonClass}
                              dropdownClass={classes.DropdownClass}
                              searchClass={classes.SearchClass}
                              disabled={isReadOnly}
                            />
                          </div>

                          <VisibilityDropdown
                            color={props.color}
                            visibility={field.visibility}
                            onChangeVisibility={value =>
                              onChangeRightFieldVisibilityHandler(value, index)
                            }
                            disabled={isReadOnly}
                          />
                        </div>
                      </div>
                    );
                  } else if (field.name === 'Facebook') {
                    return (
                      <Tooltip placement={"topRight"} title={"Facebook profile Link or Username"} key={field.name}>
                        <div
                          style={{background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor, 80)} 100%)`}}
                          className={classes.InputWrapper}
                          key={field.name}>
                          <div>
                            <IconPickerItem
                              icon={field.icon}
                              color="#919191"
                              size={16}
                            />

                            <input
                              disabled={isReadOnly}
                              placeholder={field.name}
                              value={field.value}
                              /*onChange={e =>
                                onChangeRightProfileFieldHandler(
                                  e.target.value,
                                  index,
                                )
                              }*/
                              onChange={(e) => {
                                if (e.nativeEvent.inputType === 'deleteContentBackward') {
                                  onChangeRightProfileFieldHandler(e.target.value, index)
                                } else {
                                  const value = e.target.value.trim();
                                  const isCompleteURL = value.startsWith('http://') || value.startsWith('https://');

                                  const isBackspaceKeyPressed = e.nativeEvent.inputType === 'deleteContentBackward';
                                  if (isBackspaceKeyPressed && !value) {
                                    onChangeRightProfileFieldHandler('', index);
                                  } else {
                                    let updatedValue = value;

                                    if (!isCompleteURL) {
                                      updatedValue = `https://www.facebook.com/${value}`;
                                    }

                                    onChangeRightProfileFieldHandler(updatedValue, index);
                                  }
                                }
                              }}
                            />

                            <VisibilityDropdown
                              disabled={isReadOnly}
                              color={props.color}
                              visibility={field.visibility}
                              onChangeVisibility={value =>
                                onChangeRightFieldVisibilityHandler(value, index)
                              }
                            />
                          </div>
                        </div>
                      </Tooltip>
                    );
                  } else if (field.name === 'Instagram') {
                    return (
                      <Tooltip placement={"topRight"} title={"Instagram profile Link or Username"} key={field.name}>
                        <div
                          style={{background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor, 80)} 100%)`}}
                          className={classes.InputWrapper}
                          key={field.name}>
                          <div>
                            <IconPickerItem
                              icon={field.icon}
                              color="#919191"
                              size={16}
                            />

                            <input
                              disabled={isReadOnly}
                              placeholder={field.name}
                              value={field.value}
                              onChange={(e) => {
                                if (e.nativeEvent.inputType === 'deleteContentBackward') {
                                  onChangeRightProfileFieldHandler(e.target.value, index)
                                } else {
                                  const value = e.target.value.trim();
                                  const isCompleteURL = value.startsWith('http://') || value.startsWith('https://');

                                  const isBackspaceKeyPressed = e.nativeEvent.inputType === 'deleteContentBackward';
                                  if (isBackspaceKeyPressed && !value) {
                                    onChangeRightProfileFieldHandler('', index);
                                  } else {
                                    let updatedValue = value;

                                    if (!isCompleteURL) {
                                      updatedValue = `https://www.instagram.com/${value}`;
                                    }

                                    onChangeRightProfileFieldHandler(updatedValue, index);
                                  }
                                }
                              }}
                            />

                            <VisibilityDropdown
                              disabled={isReadOnly}
                              color={props.color}
                              visibility={field.visibility}
                              onChangeVisibility={value =>
                                onChangeRightFieldVisibilityHandler(value, index)
                              }
                            />
                          </div>
                        </div>
                      </Tooltip>
                    );
                  } else if (field.name === 'LinkedIn') {
                    return (
                      <Tooltip placement={"topRight"} title={"Linkedin profile Link or Username"} key={field.name}>
                        <div
                          style={{background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor, 80)} 100%)`}}
                          className={classes.InputWrapper}
                          key={field.name}>
                          <div>
                            <IconPickerItem
                              icon={field.icon}
                              color="#919191"
                              size={16}
                            />

                            <input
                              disabled={isReadOnly}
                              placeholder={field.name}
                              value={field.value}
                              onChange={(e) => {
                                if (e.nativeEvent.inputType === 'deleteContentBackward') {
                                  onChangeRightProfileFieldHandler(e.target.value, index)
                                } else {
                                  const value = e.target.value.trim();
                                  const isCompleteURL = value.startsWith('http://') || value.startsWith('https://');

                                  const isBackspaceKeyPressed = e.nativeEvent.inputType === 'deleteContentBackward';
                                  if (isBackspaceKeyPressed && !value) {
                                    onChangeRightProfileFieldHandler('', index);
                                  } else {
                                    let updatedValue = value;

                                    if (!isCompleteURL) {
                                      updatedValue = `https://www.linkedin.com/in/${value}`;
                                    }

                                    onChangeRightProfileFieldHandler(updatedValue, index);
                                  }
                                }
                              }}
                            />

                            <VisibilityDropdown
                              disabled={isReadOnly}
                              color={props.color}
                              visibility={field.visibility}
                              onChangeVisibility={value =>
                                onChangeRightFieldVisibilityHandler(value, index)
                              }
                            />
                          </div>
                        </div>
                      </Tooltip>
                    );
                  } else if (field.name === 'Twitter') {
                    return (
                      <Tooltip placement={"topRight"} title={"Twitter profile Link or Username"} key={field.name}>
                        <div
                          style={{background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor, 80)} 100%)`}}
                          className={classes.InputWrapper}
                          key={field.name}>
                          <div>
                            <IconPickerItem
                              icon={field.icon}
                              color="#919191"
                              size={16}
                            />

                            <input
                              disabled={isReadOnly}
                              placeholder={field.name}
                              value={field.value}
                              onChange={(e) => {
                                if (e.nativeEvent.inputType === 'deleteContentBackward') {
                                  onChangeRightProfileFieldHandler(e.target.value, index)
                                } else {
                                  const value = e.target.value.trim();
                                  const isCompleteURL = value.startsWith('http://') || value.startsWith('https://');

                                  const isBackspaceKeyPressed = e.nativeEvent.inputType === 'deleteContentBackward';
                                  if (isBackspaceKeyPressed && !value) {
                                    onChangeRightProfileFieldHandler('', index);
                                  } else {
                                    let updatedValue = value;

                                    if (!isCompleteURL) {
                                      updatedValue = `https://www.twitter.com/${value}`;
                                    }

                                    onChangeRightProfileFieldHandler(updatedValue, index);
                                  }
                                }
                              }}
                            />

                            <VisibilityDropdown
                              disabled={isReadOnly}
                              color={props.color}
                              visibility={field.visibility}
                              onChangeVisibility={value =>
                                onChangeRightFieldVisibilityHandler(value, index)
                              }
                            />
                          </div>
                        </div>
                      </Tooltip>
                    );
                  } else {
                    return (
                      <div
                        style={{background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor, 80)} 100%)`}}
                        className={classes.InputWrapper}
                        key={field.name}>
                        <div>
                          <IconPickerItem
                            icon={field.icon}
                            color="#919191"
                            size={16}
                          />

                          <input
                            disabled={isReadOnly}
                            placeholder={field.name}
                            value={field.value}
                            onChange={e =>
                              onChangeRightProfileFieldHandler(
                                e.target.value,
                                index,
                              )
                            }
                          />

                          <VisibilityDropdown
                            disabled={isReadOnly}
                            color={props.color}
                            visibility={field.visibility}
                            onChangeVisibility={value =>
                              onChangeRightFieldVisibilityHandler(value, index)
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                })}
                {props.additionalProfileFields.map((field, index) => {
                  return (
                    <div
                      className={classes.AdditionalFieldWrapper}
                      key={field.name}
                    >
                      <div
                        style={{background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor, 80)} 100%)`}}
                        className={classes.InputWrapper}>
                        <div>
                          <IconPickerItem
                            icon={field.icon}
                            color="#919191"
                            size={16}
                          />

                          <input
                            disabled={isReadOnly}
                            placeholder={field.name}
                            value={field.value}
                            onChange={e =>
                              onChangeAdditionalProfileFieldHandler(
                                e.target.value,
                                index,
                              )
                            }
                          />

                          <VisibilityDropdown
                            disabled={isReadOnly}
                            color={props.color}
                            visibility={field.visibility}
                            onChangeVisibility={value =>
                              onChangeAdditionalFieldVisibilityHandler(
                                value,
                                index,
                              )
                            }
                          />
                        </div>
                      </div>
                      <div
                        className={classes.DeleteFieldButton}
                        onClick={() =>
                          onRemoveAdditionalProfileFieldHandler(index)
                        }
                      >
                        <FiTrash color="#919191" size={18}/>
                      </div>
                    </div>
                  );
                })}
                {
                  !isReadOnly &&
                  <div className={classes.AddFieldButton} onClick={() => setModalIsOpen(true)}>
                    <p>+ Add Field</p>
                  </div>
                }
                {!isReadOnly &&
                  <button
                    style={{background: `linear-gradient(91.3deg, ${props.color} 1.61%, ${createGradient(props.color, 50)} 100%)`}}
                    className={classes.SaveButtonMobile}
                    disabled={saveButtonIsClicked}
                    onClick={() =>
                      onSaveHandler(() => {
                        showToast('Successfully saved!');
                        setTimeout(() => navigate('/home'), 1000);
                        props.onClearData();
                      })
                    }
                  >
                    Save
                  </button>
                }
                {!isReadOnly &&
                  <>
                    {query.get('edit') ? (
                      <button
                        className={classes.DeleteButtonMobile}
                        onClick={() => setDeleteConfirmationVisibility(true)}
                      >
                        Delete
                      </button>
                    ) : null}
                  </>}
              </div>
            </div>
          </div>

          {modalIsOpen ? (
            <AddFieldModal
              setModalIsOpen={setModalIsOpen}
              setSelectedIcon={setSelectedIcon}
              selectedIcon={selectedIcon}
              color={props.color}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    profilePicture: state.profile.profilePicture,
    profileName: state.profile.profileName,
    leftProfileFields: state.profile.leftProfileFields,
    rightProfileFields: state.profile.rightProfileFields,
    additionalProfileFields: state.profile.additionalProfileFields,
    profiles: state.home.profiles,
    pictureLink: state.profile.pictureLink,
    color: state.home.themeColor,
    darkMode: state.profile.darkMode,
    link: state.link.link,
    companyExits: state.home.companyExits,
    companyName: state.home.companyName,
    userRole: state.auth.userRole,
    companySettings: state.settings.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCompanySettings: (showToast) => dispatch(actions.getCompanySettings(showToast)),
    onClearData: () => dispatch(actions.clearData()),
    onChangeProfilePicture: image =>
      dispatch(actions.changeProfilePicture(image)),
    onUploadProfilePicture: profile =>
      dispatch(actions.uploadProfilePicture(profile)),
    onChangeProfileName: name => dispatch(actions.changeProfileName(name)),
    onChangeLeftProfileField: (field, fieldIndex) =>
      dispatch(actions.changeLeftProfileField(field, fieldIndex)),
    onChangeRightProfileField: (field, fieldIndex) =>
      dispatch(actions.changeRightProfileField(field, fieldIndex)),
    onChangeAdditionalProfileField: (field, fieldIndex) =>
      dispatch(actions.changeAdditionalProfileField(field, fieldIndex)),
    onRemoveAdditionalProfileField: fieldIndex =>
      dispatch(actions.removeAdditionalProfileField(fieldIndex)),
    onSaveProfile: (requestData, successCallback) =>
      dispatch(actions.saveProfile(requestData, successCallback)),
    onUpdateProfile: (requestData, successCallback) =>
      dispatch(actions.updateProfile(requestData, successCallback)),
    onCreateTeamProfile: (requestData, successCallback) =>
      dispatch(actions.createTeamProfile(requestData, successCallback)),
    onChangeActiveProfile: (profileId, showToast, callback) =>
      dispatch(actions.changeActiveProfile(profileId, showToast, callback)),
    onDeleteProfile: (profileId, successCallback, showToast) =>
      dispatch(actions.deleteProfile(profileId, successCallback, showToast)),
    onChangeDarkTheme: () => dispatch(actions.changeDarkTheme()),
    onGetCompanyDetails: () => dispatch(actions.getTeamsCompanyDetails()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProfile);
