import React from 'react';
import classes from './RegisterHeader.module.scss';
import CommercialPrimeLogo from '../../../../assets/images/commercial-prime-full-logo-white.png';
import {useSearchParams} from "react-router-dom";

const RegisterHeader = () => {
  const [searchParams] = useSearchParams();
  const logo = searchParams.get('logo');
  const color = (searchParams.get('color') !== 'null' && searchParams.get('color') !== null) ? `#${searchParams.get('color')}` : '#DBB659';
  const gradient = `linear-gradient(114.54deg, #000000 0.12%, ${color+'5B'} 100%)`;

  return (
    <div className={classes.RegisterHeaderContainer} style={{background: gradient}}>
      <div className={classes.RegisterHeader}>
        <h1>
          That One Card,
          <br />
          Every Entrepreneur Needs
        </h1>
        <div className={classes.TopBox}>
          <img src={CommercialPrimeLogo} style={{display : logo === 'true' ? "none" : null}} alt="Commercial Prime" />
        </div>
        <div className={classes.MiddleBox}></div>
        <div className={classes.BottomBox}></div>
      </div>
    </div>
  );
};

export default RegisterHeader;
