import * as actionTypes from '../actions/settings/actionTypes'

const initialState = {
  data: null,
  getDataLoading: false,
  setDataLoading: false,
};

const getCompanySettingsStart = (state, action) => {
  return {
    ...state,
    getDataLoading: true,
  };
}

const getCompanySettingsSuccess = (state, action) => {
  return {
    ...state,
    data: action.payload,
  };
}

const getCompanySettingsEnd = (state, action) => {
  return {
    ...state,
    getDataLoading: false,
  };
}

const setCompanySettingsStart = (state, action) => {
  return {
    ...state,
    setDataLoading: true,
  };
}

const setCompanySettingsSuccess = (state, action) => {
  return {
    ...state,
    data: action.payload,
  };
}

const setCompanySettingsEnd = (state, action) => {
  return {
    ...state,
    setDataLoading: false,
  };
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_SETTING_START:
      return getCompanySettingsStart(state, action);
    case actionTypes.GET_COMPANY_SETTING_SUCCESS:
      return getCompanySettingsSuccess(state, action);
    case actionTypes.GET_COMPANY_SETTING_END:
      return getCompanySettingsEnd(state, action);
    case actionTypes.SET_COMPANY_SETTING_START:
      return setCompanySettingsStart(state, action);
    case actionTypes.SET_COMPANY_SETTING_SUCCESS:
      return setCompanySettingsSuccess(state, action);
    case actionTypes.SET_COMPANY_SETTING_END:
      return setCompanySettingsEnd(state, action);
    default:
      return state;
  }
};

export default reducer;