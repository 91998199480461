import React from 'react';
import {useNavigate} from 'react-router-dom';
import classes from './Home.module.scss';
import ProfileCard from './ProfileCard/ProfileCard';
import * as actions from '../../../../store/actions';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import StatusChangeConfirmation from './StatusChangeConfirmation/StatusChangeConfirmation';
import PreviewConfirmation from './PreviewConfirmation/PreviewConfirmation';
import {ClipLoader} from 'react-spinners';
import {createGradient} from "../../../../shared/Constants";
import {HiQrcode} from "react-icons/hi";
import QRCodePopup from "./QRCodePopup/QRCodePopup";
import ProfileLimitReached from "../../../UI/ProfileLimitReached/ProfileLimitReached";
import {Switch} from 'antd';
import PrivacyMode from "../../../../assets/images/highPrivacy.png";
import {changePrivacyMode} from "../../../../store/actions";
import PrivacyModeChangeConfirm from "../../../UI/PrivacyModeChangeConfirm/PrivacyModeChangeConfirm";

const Home = props => {
  const navigate = useNavigate();

  const [activationStatusPopupVisible, setActivationStatusPopupVisibility] =
    React.useState(false);

  const [qrCodePopupVisible, setQrCodePopupVisibility] = React.useState(false);

  const [profileLimitReachedPopupVisible, setProfileLimitReachedPopupVisible] = React.useState(false);

  const [privacyModePopupVisible, setPrivacyModePopupVisibility] = React.useState(false);

  const [confirmationVisible, setConfirmationVisibility] =
    React.useState(false);

  const [profileId, setProfileId] = React.useState(null);

  const [privacyMode, setPrivacyMode] = React.useState(props.privacyMode);

  React.useEffect(() => {
    props.onGetAllProfiles();
    props.getLinkByUsername(props.user)
  }, []);

  const onChangeActiveProfileHandler = (profileId, callback) => {
    const currentActiveProfile = props.profiles.find(profile => profile.active);

    if (currentActiveProfile.id !== profileId) {
      props.onChangeActiveProfile(
        //props.user.userId,
        profileId,
        showToast,
        callback,
      );
    }
  };

  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style: {background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 35)} 100%)`}
    });
  };

  const handleSwitchClick = () => {
    props.onChangeHighPrivacyMode(!props.privacyMode);
    setPrivacyModePopupVisibility(false)
  };

  const onConfirmationHandler = () => {
    onChangeActiveProfileHandler(profileId, () => {
      setConfirmationVisibility(false);
      window.open(process.env.REACT_APP_SHARABLE_LINK + '@' + props.link[0], '_blank');
    });
  };

  return (
    <div className={classes.DashboardContent}>
      {activationStatusPopupVisible ? (
        <Backdrop onClick={() => setActivationStatusPopupVisibility(false)}>
          <StatusChangeConfirmation
            onClose={() => setActivationStatusPopupVisibility(false)}
          />
        </Backdrop>
      ) : null}

      {privacyModePopupVisible ? (
        <Backdrop onClick={() => setPrivacyModePopupVisibility(false)}>
          <PrivacyModeChangeConfirm
            onClose={() => setPrivacyModePopupVisibility(false)}
            color={props.color}
            active={props.privacyMode}
            onClick={handleSwitchClick}
          />
        </Backdrop>
      ) : null}

      {confirmationVisible ? (
        <Backdrop onClick={() => setConfirmationVisibility(false)}>
          <PreviewConfirmation
            color={props.color}
            onClose={() => setConfirmationVisibility(false)}
            onConfirmation={onConfirmationHandler}
          />
        </Backdrop>
      ) : null}

      {profileLimitReachedPopupVisible ? (
        <Backdrop onClick={() => setProfileLimitReachedPopupVisible(false)}>
          <ProfileLimitReached
            color={props.color}
            onClose={() => setProfileLimitReachedPopupVisible(false)}
            //onConfirmation={onConfirmationHandler}
          />
        </Backdrop>
      ) : null}

      {qrCodePopupVisible ? (
        <Backdrop onClick={() => setQrCodePopupVisibility(false)}>
          <QRCodePopup
            onClose={() => setQrCodePopupVisibility(false)}
            color={props.color}
          />
        </Backdrop>
      ) : null}
      <div className={classes.ContentHeader}>
        <div>
          <p className={classes.Greeting}>
            Hello, <span style={{color: props.color}}>{props.user}!</span>
          </p>
          {props.changingVcardStatus ? (
            <div className={classes.SpinnerContainer}>
              <ClipLoader color={props.color} loading={true} size={20}/>
            </div>
          ) : (
            <p
              className={
                props.vcardStatus === 'ACTIVE'
                  ? classes.AccountStatusActive
                  : classes.AccountStatusInactive
              }
              onClick={() => setActivationStatusPopupVisibility(true)}
            >
              {props.vcardStatus === 'ACTIVE' ? 'Card Active' : 'Card Inactive'}
            </p>
          )}
        </div>
        <div>
          {props.tenantEnabledPrivacyMode &&
            <div className={classes.HighPrivacySwitch}>
              <img src={PrivacyMode} alt={"privacy mode"} width={34}/>
              <p>High Privacy <br/>Mode</p>
              <Switch
                checked={props.privacyMode}
                checkedChildren="on"
                unCheckedChildren="off"
                // onClick={handleSwitchClick}
                onClick={() => setPrivacyModePopupVisibility(true)}
              />
            </div>}

          <HiQrcode className={classes.QrButton} onClick={() => setQrCodePopupVisibility(true)}/>
          <button
            className={classes.CreateProfileButton}
            onClick={() => {
              if (props.profileLimit > props.profiles.length) {
                navigate('/create-profile')
              } else {
                setProfileLimitReachedPopupVisible(true);
              }
            }}
          >
            + Create New Profile
          </button>
        </div>
      </div>
      <div className={classes.Content}>
        {props.profiles.map(profile => {
          return (
            <ProfileCard
              showConfirmation={id => {
                const profile = props.profiles.find(
                  profile => profile.id === id,
                );

                if (profile.active) {
                  window.open(
                    process.env.REACT_APP_SHARABLE_LINK + '@' + props.link[0],
                    '_blank',
                  );
                } else {
                  setConfirmationVisibility(true);
                  setProfileId(id);
                }
              }}
              profile={profile}
              key={profile.id}
              onChangeActiveProfileHandler={onChangeActiveProfileHandler}
            />
          );
        })}

        <button
          className={classes.CreateNewProfileButtonMobile}
          onClick={() => {
            if (props.profileLimit > props.profiles.length) {
              navigate('/create-profile')
            } else {
              setProfileLimitReachedPopupVisible(true);
            }
          }}
        >
          Create New Profile
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {

  return {
    user: state.auth.user,
    vcardStatus: state.home.vcardStatus,
    profiles: state.home.profiles,
    changingVcardStatus: state.home.changingVcardStatus,
    role: state.auth.userRole,
    color: state.home.themeColor,
    profileLimit: state.home.profileLimit,
    link: state.link.link,
    privacyMode: state.home.privacyMode,
    tenantEnabledPrivacyMode: state.home.tenantEnabledPrivacyMode,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    onGetAllProfiles: () => dispatch(actions.getAllProfiles()),
    onChangeActiveProfile: (profileId, showToast, callback) =>
      dispatch(
        actions.changeActiveProfile(profileId, showToast, callback),
      ),
    getLinkByUsername: (userName) => dispatch(actions.getLinkByUsername(userName)),
    onChangeHighPrivacyMode: value => dispatch(changePrivacyMode(value)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
