import * as actionTypes from '../actions/auth/actionTypes';

const initialState = {
  token: null,
  user: null,
  loading: false,
  changingVcardStatus: false,
  userRole: null,
};

const signInStart = (state, action) => {
  return {...state, loading: true};
};
const signInSuccess = (state, action) => {
  return {...state, ...action.payload, loading: false};
};
const signInFail = (state, action) => {
  return {...state, loading: false};
};

const signOut = (state, action) => {
  return {...state, token: null, user: null, userRole: null};
};

const registerStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const registerEnd = (state, action) => {
  return {
    ...state,
    loading: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGN_IN_START:
      return signInStart(state, action);
    case actionTypes.SIGN_IN_SUCCESS:
      return signInSuccess(state, action);
    case actionTypes.SIGN_IN_FAIL:
      return signInFail(state, action);
    case actionTypes.REGISTER_START:
      return registerStart(state, action);
    case actionTypes.SIGN_OUT:
      return signOut(state, action);
    case actionTypes.REGISTER_END:
      return registerEnd(state, action);
    default:
      return state;
  }
};

export default reducer;
