import React from 'react';
import classes from './PrimeTabMenu.module.scss';
import {RiHome4Fill} from 'react-icons/ri';
import AnalyticsImage from '../../../assets/images/primeMenu/AnalyticsSVG.svg';
import ContactsImage from '../../../assets/images/primeMenu/ContactsSVG.svg';
import LinkImage from '../../../assets/images/primeMenu/LinkSVG.svg';
import AccountImage from '../../../assets/images/primeMenu/AccountsSVG.svg';
import TeamsImage from '../../../assets/images/primeMenu/Teams.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import * as actions from '../../../store/actions';
import {connect} from 'react-redux';
import SVG from 'react-inlinesvg';

const PrimeTabMenu = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const onNavigate = path => {
    navigate(path);
  };

  return (
    <div className={classes.PrimeTabMenu}>
      <div className={classes.TabOptions}>
        <div
          className={
            location.pathname === '/home'
              ? classes.TabOptionActive
              : classes.TabOptionInactive
          }
          onClick={() => {
            onNavigate('/home');
            if (props.dropdownVisible) {
              props.onToggleExportImportDropdown();
            }
          }}
        >
          <RiHome4Fill
            size={20}
            style={{color: location.pathname === '/home' ? "white" : props.color}}
          />

          <p style={{color: location.pathname === '/home' ? "white" : props.color}}>Home</p>
        </div>
        <div className={
          location.pathname === '/home/analytics'
            ? classes.TabOptionActive
            : classes.TabOptionInactive
        }
             onClick={() => onNavigate('/home/analytics')}
        >
          <SVG
            className={classes.SVG}
            src={AnalyticsImage}
            alt="Analytics"
            style={{fill: location.pathname === '/home/analytics' ? "white" : props.color}}/>
          <p style={{color: location.pathname === '/home/analytics' ? "white" : props.color}}>Analytics</p>
        </div>
        <div
          className={
            location.pathname === '/home/contacts'
              ? classes.TabOptionActive
              : classes.TabOptionInactive
          }
          onClick={() => onNavigate('/home/contacts')}
        >
          <SVG
            src={ContactsImage}
            alt="Contacts"
            className={classes.SVG}
            style={{fill: location.pathname === '/home/contacts' ? "white" : props.color}}
          />
          <p style={{color: location.pathname === '/home/contacts' ? "white" : props.color}}>Contacts</p>
        </div>
        <div
          className={
            location.pathname === '/home/link'
              ? classes.TabOptionActive
              : classes.TabOptionInactive
          }
          onClick={() => onNavigate('/home/link')}
        >
          <SVG
            src={LinkImage} alt="Link"
            className={classes.SVG}
            style={{fill: location.pathname === '/home/link' ? "white" : props.color}}
          />
          <p
            style={{color: location.pathname === '/home/link' ? "white" : props.color}}
          >
            Link
          </p>
        </div>
        <div
          className={
            location.pathname === '/home/account'
              ? classes.TabOptionActive
              : classes.TabOptionInactive
          }
          onClick={() => onNavigate('/home/account')}
        >
          <SVG
            src={AccountImage}
            alt="Account"
            className={classes.SVG}
            style={{fill: location.pathname === '/home/account' ? "white" : props.color}}
          />
          <p style={{color: location.pathname === '/home/account' ? "white" : props.color}}>Account</p>
        </div>
        {props.userRole === 'ROLE_COMPANY_ADMIN' &&
          <div
            className={
              location.pathname.includes('/home/teams')
                ? classes.TabOptionActive
                : classes.TabOptionInactive
            }
            onClick={() => onNavigate('/home/teams')}
          >
            <SVG
              src={TeamsImage}
              alt="Teams"
              className={classes.SVG}
              style={{fill: location.pathname.includes('/home/teams') ? "white" : props.color}}
            />
            <p style={{color: location.pathname.includes('/home/teams') ? "white" : props.color}}>Teams</p>
          </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    dropdownVisible: state.contact.exportImportDropdownVisible,
    color: state.home.themeColor,
    userRole: state.auth.userRole,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleExportImportDropdown: () =>
      dispatch(actions.toggleExportImportDropdown()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimeTabMenu);
