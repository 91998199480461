import React from 'react';
import classes from './RegisterForm.module.scss';
import Input from '../../../UI/Input/Input';
import {RiUser3Line, RiUserLocationLine} from 'react-icons/ri';
import {MdOutlineEmail, MdOutlineGeneratingTokens} from 'react-icons/md';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import validator from 'validator';
import * as actions from '../../../../store/actions';
import {connect} from 'react-redux';
import {ClipLoader} from 'react-spinners';
import ct from 'countries-and-timezones';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {isValidPhoneNumber} from 'libphonenumber-js';
import {IoMdCheckmarkCircleOutline} from "react-icons/io";
import {AiFillCloseCircle} from "react-icons/ai";
import {adjustColorBrightness, createGradient} from "../../../../shared/Constants";

const RegisterForm = props => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const color = searchParams.get('color') !== 'null' ? `#${searchParams.get('color')}` : '#DBB659';
  const adjustColor = adjustColorBrightness(color, 65);
  const defaultGradient = "linear-gradient(142deg, rgba(132, 111, 59, 1) 0%, rgba(53, 47, 32, 1) 100%)";
  const gradient = color === '#DBB659' || color === "#null" ? defaultGradient : `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor, 60)} 100%)`;

  const tenantName = "CommercialPrime";
  const [username, setUsername] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [contactNo, setContact] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [registrationToken, setRegistrationToken] = React.useState(token || '');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [isUserNameAvailable, setIsUserNameAvailable] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const getCountry = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const obj = ct.getCountryForTimezone(tz)
    return obj.id.toLowerCase();
  }

  React.useEffect(() => {
    if (token === null) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await props.onIsUserNameExist(username);
        if (username.length < 4) {
          setIsUserNameAvailable(true);
        } else {
          setIsUserNameAvailable(response);
        }

      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [username, props.onIsUserNameExist]);

  const onRegisterHandler = () => {
    if (formIsValid()) {
      props.onRegister(
        firstName,
        'PERSONAL',
        lastName,
        '0',
        contactNo,
        email,
        password,
        registrationToken,
        tenantName,
        username,
        successCallback,
        showToast,
      );
    }
  };

  const successCallback = () => {
    showToast('Registered Successfully!');
    navigate('/');
  };

  const formIsValid = () => {
    const usernameRegex = /^(?!\s)[\w\s]+$/;
    const nameRegex = /^[a-zA-Z\s']+$/;

    if (username === '') {
      showToast('Username cannot be empty');
    } else if (!username.match(usernameRegex)) {
      showToast('Username can only contain letters and numbers');
    } else if (registrationToken === '') {
      showToast('Registration token cannot be empty');
    } else if (firstName === '') {
      showToast('First name cannot be empty');
    } else if (!firstName.match(nameRegex)) {
      showToast('First name is invalid');
    } else if (email === '') {
      showToast('Email cannot be empty');
    } else if (!validator.isEmail(email)) {
      showToast('Invalid email address');
    } else if (lastName === '') {
      showToast('Last name cannot be empty');
    } else if (!lastName.match(nameRegex)) {
      showToast('Last name is invalid');
    } else if (contactNo === '') {
      showToast('Contact number cannot be empty');
    } else if (!isValidPhoneNumber(contactNo)) {
      showToast('Invalid phone number');
    } else if (password === '') {
      showToast('Password cannot be empty');
    } else if (confirmPassword === '') {
      showToast('Confirm password cannot be empty');
    } else if (password !== confirmPassword) {
      showToast('Passwords do not match');
    } else {
      return true;
    }

    return false;
  };

  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style: {
        background: `linear-gradient(142deg, 
        ${color === '#null' ? '#dbb659' : color} 0%, 
        ${createGradient(color === '#null' ? '#dbb659' : color, 35)} 100%)`
      }
    });
  };

  const handlePhoneChange = (phone) => {
    setContact('+' + phone);
  };

  return (
    <div className={classes.RegisterFormContainer}>
      <div className={classes.RegisterForm}>
        <div className={classes.RegisterCards}>
          <div>
            <div className={classes.Input} style={{background: gradient}}>
              <div className={classes.IconContainer}><RiUser3Line/></div>
              <input
                type={'text'}
                placeholder="Username"
                onChange={event => setUsername(event.target.value.replace(/[^a-zA-Z0-9]+/g, ''))}
                value={username}
              />
              <div
                className={classes.TickIcon}
              >
                {username.length === 0 ? null :
                  (isUserNameAvailable ?
                    <AiFillCloseCircle color={'#ff3b3b'}/>
                    : <IoMdCheckmarkCircleOutline color={'rgb(113 191 63)'}/>)
                }

              </div>
            </div>
            <Input
              type="text"
              placeholder="First name"
              icon={<RiUser3Line/>}
              value={firstName}
              onChange={event => setFirstName(event.target.value)}
              style={gradient}
            />
            <Input
              type="text"
              placeholder="Last name"
              value={lastName}
              icon={<RiUserLocationLine/>}
              onChange={event => setLastName(event.target.value)}
              style={gradient}
            />
            <div className={classes.Input} style={{background: gradient}}>
              <PhoneInput
                country={getCountry()}
                countryCodeEditable={false}
                value={contactNo}
                onChange={handlePhoneChange}
                enableSearch={true}
                disableSearchIcon={true}
                inputClass={classes.PhoneInput}
                buttonClass={classes.ButtonClass}
                dropdownClass={classes.DropdownClass}
                searchClass={classes.SearchClass}
              />
            </div>
          </div>
          <div>
            <Input
              type="text"
              placeholder="Registration Token"
              icon={<MdOutlineGeneratingTokens/>}
              value={registrationToken}
              onChange={event => setRegistrationToken(event.target.value.replace(/[^a-zA-Z0-9-_]+/g, ''))}
              isDisabled={isDisabled}
              style={gradient}
            />

            <Input
              type="text"
              placeholder="Email Address"
              icon={<MdOutlineEmail/>}
              value={email}
              autocomplete="off"
              onChange={event => setEmail(event.target.value)}
              style={gradient}
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={event => setPassword(event.target.value)}
              style={gradient}
            />
            <Input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={event => setConfirmPassword(event.target.value)}
              style={gradient}
            />
          </div>
        </div>

        {props.loading ? (
          <div className={classes.SpinnerContainer}>
            <ClipLoader color={'#dbb659'} loading={true} size={20}/>
          </div>
        ) : (

          <button
            className={classes.RegisterButton}
            onClick={onRegisterHandler}
            style={{
              background: `linear-gradient(142deg, ${color} 0%, ${createGradient(color, 50)} 100%)`
            }}
          >
            Register
          </button>
        )}

        <div
          className={classes.AlreadyHaveAccount}
          onClick={() => navigate('/')}
        >
          <p>
            Already have an account? <span style={{color: color}}>Log In</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onIsUserNameExist: username => dispatch(actions.isUserNameExist(username)),
    onRegister: (
      firstName,
      accountType,
      lastName,
      companyId,
      contactNo,
      email,
      password,
      registrationToken,
      tenantName,
      userName,
      successCallback,
      showToast,
    ) =>
      dispatch(
        actions.register(
          firstName,
          accountType,
          lastName,
          companyId,
          contactNo,
          email,
          password,
          registrationToken,
          tenantName,
          userName,
          successCallback,
          showToast,
        ),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
