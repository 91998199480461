import React from "react";
import classes from "./LoginPage.module.scss";
import Div100vh from "react-div-100vh";

import LoginHeader from "../../components/Auth/Login/LoginHeader/LoginHeader";
import LoginForm from "../../components/Auth/Login/LoginForm/LoginForm";

const Login = () => {
  return (
    <Div100vh className={classes.LoginPage}>
      <LoginHeader />
      <LoginForm />
    </Div100vh>
  );
};

export default Login;
