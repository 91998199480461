import React from 'react';
import classes from './CreateProfilePage.module.scss';

import CreateProfile from '../../components/CreateProfile/CreateProfile';

const CreateProfilePage = () => {
  return (
    <div className={classes.CreateProfilePage}>
      <CreateProfile />
    </div>
  );
};

export default CreateProfilePage;
