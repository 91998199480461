import React from 'react';
import classes from './DashboardBody.module.scss';

import {Outlet} from 'react-router-dom';
import InstallPWAButton from "../../UI/PWAInstallButton/InstallPWAButton";

const DashboardBody = ({toggleMenu}) => {
  return (
    <div className={classes.DashboardBody}>
      <Outlet context={{toggleMenu}} />
      <InstallPWAButton small={true}/>
    </div>
  );
};

export default DashboardBody;
