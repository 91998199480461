export const GET_ALL_PROFILES_START = 'GET_ALL_PROFILES_START';
export const GET_ALL_PROFILES_SUCCESS = 'GET_ALL_PROFILES_SUCCESS';
export const GET_ALL_PROFILES_FAIL = 'GET_ALL_PROFILES_FAIL';

export const CHANGE_VCARD_STATUS_START = 'CHANGE_VCARD_STATUS_START';
export const CHANGE_VCARD_STATUS_SUCCESS = 'CHANGE_VCARD_STATUS_SUCCESS';
export const CHANGE_VCARD_STATUS_FAIL = 'CHANGE_VCARD_STATUS_FAIL';

export const CHANGE_PRIVACY_MODE_SUCCESS = 'CHANGE_PRIVACY_MODE_SUCCESS';

export const CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR';
