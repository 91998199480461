import React, {useState} from 'react';
import classes from "./ViewUser.module.scss";
import {MdAlternateEmail, MdClose} from "react-icons/md";
import {FiAnchor} from "react-icons/fi";
import {createGradient} from "../../../../../../../shared/Constants";
import {connect} from 'react-redux';
import {deleteTeamUser, updateTeamsUserSuccess} from "../../../../../../../store/actions";
import {ClipLoader} from "react-spinners";
import validator from 'validator';
import {toast} from "react-toastify";
import Backdrop from "../../../../../../UI/Backdrop/Backdrop";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup/DeleteConfirmationPopup";
import {useNavigate} from "react-router-dom";
import ct from "countries-and-timezones";
import * as actions from "../../../../../../../store/actions";
import {AiFillCloseCircle, AiOutlineCheck, AiOutlineClose} from "react-icons/ai";
import {IoMdCheckmarkCircleOutline} from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import {isValidPhoneNumber} from "libphonenumber-js";
import {Dropdown, Space, Typography, Menu, Switch} from 'antd';
import {BiChevronDown} from "react-icons/bi";
import ProfileLimitReached from "../../../../../../UI/ProfileLimitReached/ProfileLimitReached";
import SetAsAdminConfirmationPopup from "./SetAsAdminConfirmationPopup/SetAsAdminConfirmationPopup";

const ViewUser = (props) => {
  const navigate = useNavigate();

  const [username, setUsername] = useState(typeof props.data.userName === "string" ? props.data.userName : "");
  const [fullName, setFullName] = useState(props.data.firstName + " " + props.data.lastName);
  const [phoneNumber, setPhoneNumber] = useState(typeof props.data.phoneNumber === "string" ? props.data.phoneNumber : "");
  const [QRCode, setQRCode] = useState(props.data.products === null ? "null" : props.data.products.link);
  const [email, setEmail] = useState(typeof props.data.email === "string" ? props.data.email : "");
  const [status, setStatus] = useState(typeof props.data.status === "string" ? props.data.status : "");
  const [userRole, setUserRole] = useState(typeof props.data.role === "string" ? props.data.role : "");
  const [teamsUserDeletePopupVisible, setTeamsUserDeletePopupVisible] = useState(false);
  const [userToAdminPopupVisible, setUserToAdminPopupVisible] = useState(false);
  const [isUserNameAvailable, setIsUserNameAvailable] = React.useState(false);
  const [staticUsername, setStaticUsername] = useState(typeof props.data.userName === "string" ? props.data.userName : "");
  const [profileLimitReachedPopupVisible, setProfileLimitReachedPopupVisible] = useState(false);

  const getCountry = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const obj = ct.getCountryForTimezone(tz)
    return obj.id.toLowerCase();
  }

  const handlePhoneChange = (phone) => {
    setPhoneNumber(phone);
  };
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await props.onIsUserNameExist(username);
        if (username.length < 4) {
          setIsUserNameAvailable(true);
        }
        if (username === staticUsername) {
          setIsUserNameAvailable(false);
        } else {
          setIsUserNameAvailable(response);
        }

      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [username, props.onIsUserNameExist]);

  const splitName = fullName.split(' ');

  const companyUserUpdate = () => {
    if (formIsValid()) {
      props.onUpdateCompanyUser({
          accountId: props.data.id,
          contactNo: phoneNumber.startsWith("+") ? phoneNumber : "+" + phoneNumber,
          email,
          firstName: splitName[0],
          lastName: splitName[1],
          qrLink: QRCode,
          status: status.toUpperCase(),
          userName: username,
          role: userRole
        },
        showToast,
        props.onClose,
        props.loggedInUser,
        {
          username: props.data.userName,
          role: props.data.role
        }
      );
    }
  };

  const formIsValid = () => {
    if (username.trim() === '') {
      showToast('Username cannot be empty');
      return false;
    }
    if (!validator.isAlphanumeric(username.trim())) {
      showToast('Username should contain only letters and numbers');
      return false;
    }
    if (username.trim().length < 4) {
      showToast('Username is too short');
      return false;
    }
    if (isUserNameAvailable) {
      showToast('Username already exists');
      return false;
    }

    if (fullName.trim() === '') {
      showToast('Full name cannot be empty');
      return false;
    }

    const names = fullName.split(' ');
    const firstName = names[0];
    const lastName = names.slice(1).join(' ');

    if (names.length !== 2 || firstName.trim() === '' || lastName.trim() === '') {
      showToast('Please enter first name and last name');
      return false;
    }

    if (!validator.isAlpha(firstName.trim()) || !validator.isAlpha(lastName.trim())) {
      showToast('Name should contain only letters');
      return false;
    }

    if (phoneNumber.trim() === '') {
      showToast('Phone number cannot be empty');
      return false;
    }

    let tempNumber = phoneNumber.startsWith("+") ? phoneNumber : "+" + phoneNumber;

    if (!isValidPhoneNumber(tempNumber)) {
      showToast('Invalid phone number');
      return false;
    }
    if (email.trim() === '') {
      showToast('Email cannot be empty');
      return false;
    }
    if (!validator.isEmail(email.trim())) {
      showToast('Invalid email address');
      return false;
    }
    return true;
  };


  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style: {background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 35)} 100%)`}
    });
  };

  const commonClose = (bool) => {
    if (bool) {
      props.onClose();
      setTeamsUserDeletePopupVisible(false);
    } else {
      setTeamsUserDeletePopupVisible(false);
    }
  };

  const handleMenuClick = (event) => {
    const selectedStatus = event.key;
    setStatus(selectedStatus);
  };

  return (
    <section className={classes.ViewUser}>
      {teamsUserDeletePopupVisible ? (
        <Backdrop onClick={() => setTeamsUserDeletePopupVisible(false)}>
          <DeleteConfirmationPopup
            onClose={() => {
              setTeamsUserDeletePopupVisible(false)
            }
            }
            color={props.color}
            onDelete={() => {
              props.onDeleteTeamsUser(props.data.id, showToast, commonClose);
            }}
            loading={props.dLoading}
          />
        </Backdrop>
      ) : null}

      {profileLimitReachedPopupVisible ? (
        <Backdrop onClick={() => setProfileLimitReachedPopupVisible(false)}>
          <ProfileLimitReached
            color={props.color}
            onClose={() => setProfileLimitReachedPopupVisible(false)}
          />
        </Backdrop>

      ) : null}

      { userToAdminPopupVisible ? (
        <Backdrop onClick={() => setUserToAdminPopupVisible(false)}>
          <SetAsAdminConfirmationPopup
            onClose={() => {
              setUserToAdminPopupVisible(false)
            }
            }
            color={props.color}
            onConfirm={() => {
              setUserRole('ROLE_COMPANY_ADMIN')
              setUserToAdminPopupVisible(false)
            }}
            username={username}/>
        </Backdrop>
      ) : null}

      <div className={classes.CloseButtonContainer}>
        <h1>User details: {username}</h1>
        <div onClick={props.onClose}>
          <MdClose color="#C5C5C5" size={20}/>
        </div>
      </div>

      <div className={classes.InputContainer}>
        <div className={classes.InputsWrapper}>
          <div className={classes.Detail}>
            <p className={classes.Label}>Full Name</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="Full name" value={fullName} onChange={e => setFullName(e.target.value)}/>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <p className={classes.Label}>Username</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="Username" value={username} onChange={e => setUsername(e.target.value)}/>
                <div className={classes.TickIcon}>
                  {username.toLowerCase() === staticUsername.toLowerCase() ? null :
                    (isUserNameAvailable ?
                      <AiFillCloseCircle color={'#ff3b3b'}/>
                      : <IoMdCheckmarkCircleOutline color={'rgb(113 191 63)'}/>)
                  }
                </div>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <p className={classes.Label}>Phone Number</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <PhoneInput
                  country={getCountry()}
                  countryCodeEditable={false}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  enableSearch={true}
                  disableSearchIcon={true}
                  inputClass={classes.PhoneInput}
                  buttonClass={classes.ButtonClass}
                  dropdownClass={classes.DropdownClass}
                  searchClass={classes.SearchClass}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={classes.InputsWrapper}>
          <div className={classes.Detail}>
            <p className={classes.Label}>Email Address</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <MdAlternateEmail size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="Email Address" value={email} onChange={event => setEmail(event.target.value)}/>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <p className={classes.Label}>User Status</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>

                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <Dropdown
                  overlay={
                    <Menu onClick={handleMenuClick} selectedKeys={[status]}>
                      <Menu.Item key="Active">Active</Menu.Item>
                      <Menu.Item key="Deactive">Deactive</Menu.Item>
                    </Menu>
                  }
                >
                  <Typography>
                    <Space>
                      {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
                      <BiChevronDown/>
                    </Space>
                  </Typography>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <p className={classes.Label}>QR Code</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="QR Code" value={QRCode} onChange={event => setQRCode(event.target.value)}/>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.MobileInputWrapper}>
          <div className={classes.Detail}>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="Full name" value={fullName} onChange={event => setFullName(event.target.value)}/>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="Username" value={username} onChange={event => setUsername(event.target.value)}/>
                <div className={classes.TickIcon}>
                  {username.toLowerCase() === staticUsername.toLowerCase() ? null :
                    (isUserNameAvailable ?
                      <AiFillCloseCircle color={'#ff3b3b'}/>
                      : <IoMdCheckmarkCircleOutline color={'rgb(113 191 63)'}/>)
                  }
                </div>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <PhoneInput
                  country={getCountry()}
                  countryCodeEditable={true}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  enableSearch={true}
                  disableSearchIcon={true}
                  inputClass={classes.PhoneInput}
                  buttonClass={classes.ButtonClass}
                  dropdownClass={classes.DropdownClass}
                  searchClass={classes.SearchClass}
                />
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <MdAlternateEmail size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="Email Address" value={email} onChange={event => setEmail(event.target.value)}/>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="QR Code" value={QRCode} onChange={event => setQRCode(event.target.value)}/>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <Dropdown
                  overlay={
                    <Menu onClick={handleMenuClick} selectedKeys={[status]}>
                      <Menu.Item key="Active">Active</Menu.Item>
                      <Menu.Item key="Deactive">Deactive</Menu.Item>
                    </Menu>
                  }
                >
                  <Typography>
                    <Space>
                      {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
                      <BiChevronDown/>
                    </Space>
                  </Typography>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.BottomButtonWrapper}>
        <div className={classes.ButtonGroup}>
          <button className={classes.Button} onClick={() => setTeamsUserDeletePopupVisible(true)}>Delete</button>


          <Space size="middle">
            <Switch
              checked={userRole === 'ROLE_COMPANY_ADMIN'}
              checkedChildren={<AiOutlineCheck/>}
              unCheckedChildren={<AiOutlineClose/>}
              onChange={(checked) => checked ? setUserToAdminPopupVisible(true) : setUserRole('ROLE_CORPORATE_USER')}
            />
            <label>Company<br/>Admin</label>
          </Space>
        </div>

        {/*<button onClick={() => {*/}
        {/*  if (props.data.exitsProfileLimit < props.data.profileLimit) {*/}
        {/*    navigate(`/home/teams/create-profile?create=${props.data.id}`);*/}
        {/*  } else {*/}
        {/*    setProfileLimitReachedPopupVisible(true);*/}
        {/*  }*/}
        {/*}}>*/}
        {/*  Create Profile*/}
        {/*</button>*/}

        <div>
          <button className={classes.Button} onClick={() => {
            if (props.data.exitsProfileLimit < props.data.profileLimit) {
              navigate(`/home/teams/create-profile?create=${props.data.id}`);
            } else {
              setProfileLimitReachedPopupVisible(true);
            }
          }}>
            Create Profile
          </button>

          {/*<button onClick={() => setTeamsUserDeletePopupVisible(true)}>Delete</button>*/}

          <button
            className={classes.Button}
            style={{
              background:
                `linear-gradient(91.3deg, 
                ${props.color} 
                1.61%, 
                ${createGradient(props.color, 50)} 
                100%)`
            }}
            onClick={companyUserUpdate}
          >
            {props.loading === true ? (
              <div className={classes.SpinnerContainer}>
                <ClipLoader loading={true} color={"white"} size={18}/>
              </div>
            ) : (
              <p>Update</p>
            )
            }
          </button>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: {
      username: state.auth.user,
      role: state.auth.userRole
    },
    loading: state.teams.loading,
    dLoading: state.teams.dLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateCompanyUser: (data, showToast, onClose, loggedInUser, pastData) => dispatch(updateTeamsUserSuccess(data, showToast, onClose, loggedInUser, pastData)),
    onDeleteTeamsUser: (id, showToast, commonClose) => dispatch(deleteTeamUser(id, showToast, commonClose)),
    onIsUserNameExist: username => dispatch(actions.isUserNameExist(username)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);
