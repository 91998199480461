import React, {useState} from 'react';
import classes from './NewUser.module.scss'
import {FiAnchor} from "react-icons/fi";
import {MdClose, MdAlternateEmail} from "react-icons/md";
import {createGradient} from "../../../../../../../shared/Constants";
import {addTeamsUser} from "../../../../../../../store/actions";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import validator from "validator";
import {ClipLoader} from "react-spinners";
import * as actions from "../../../../../../../store/actions";
import {AiFillCloseCircle} from "react-icons/ai";
import {IoMdCheckmarkCircleOutline} from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import ct from "countries-and-timezones";
import {isValidPhoneNumber} from "libphonenumber-js";

const NewUser = (props) => {
  const [firstName, setFirstNme] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [qrLink, setQrLink] = useState('');
  const [isUserNameAvailable, setIsUserNameAvailable] = React.useState(false);

  const reset = () => {
    setUserName('');
    setFirstNme('');
    setLastName('');
    setPhoneNumber('');
    setEmail('');
    setQrLink('');
  };

  const onSaveAction = () => {
    if (formIsValid()){
      const dataSet = {
        contactNo: "+"+phoneNumber,
        email,
        firstName,
        lastName,
        qrLink,
        userName
      };
      props.onAddNewTeamUser(dataSet, showToast,props.onClose)
    }
  };

  const getCountry =  () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const obj = ct.getCountryForTimezone(tz)
    return obj.id.toLowerCase();
  }

  const handlePhoneChange = (phone) => {
    setPhoneNumber(phone);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await props.onIsUserNameExist(userName);
        if (userName.length < 4){
          setIsUserNameAvailable(true);
        }else{
          setIsUserNameAvailable(response);
        }

      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [userName, props.onIsUserNameExist]);

  const formIsValid = () => {

    if (firstName.trim() === '') {
      showToast('First name cannot be empty');
      return false;
    }
    if (!validator.isAlpha(firstName.trim())) {
      showToast('First name should contain only letters');
      return false;
    }
    if (lastName.trim() === '') {
      showToast('Last name cannot be empty');
      return false;
    }
    if (!validator.isAlpha(lastName.trim())) {
      showToast('Last name should contain only letters');
      return false;
    }

    if (userName.trim() === '') {
      showToast('Username cannot be empty');
      return false;
    }
    if (isUserNameAvailable){
      showToast('Username is invalid');
      return false;
    }
    if (!validator.isAlphanumeric(userName.trim())) {
      showToast('Username should contain only letters and numbers');
      return false;
    }
    if (email.trim() === '') {
      showToast('Email cannot be empty');
      return false;
    }
    if (!validator.isEmail(email.trim())) {
      showToast('Invalid email address');
      return false;
    }
    if (phoneNumber.trim() === '') {
      showToast('Phone number cannot be empty');
      return false;
    }
    if (!isValidPhoneNumber("+"+phoneNumber)) {
      showToast('Invalid phone number');
      return false;
    }
    if (qrLink.trim() === '') {
      showToast('QR Code cannot be empty');
      return false;
    }
    return true;
  };

  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style: {background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 35)} 100%)`}
    });
  };

  return (
    <section className={classes.NewUser}>
      <div className={classes.CloseButtonContainer}>
        <h1>Create New User</h1>
        <div onClick={props.onClose}>
          <MdClose color="#C5C5C5" size={20}/>
        </div>
      </div>

      <div className={classes.InputContainer}>
        <div className={classes.InputsWrapper}>
          <div className={classes.Detail}>
            <p className={classes.Label}>First Name</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="First name" value={firstName}
                       onChange={event => setFirstNme(event.target.value.replace(/[^a-zA-Z]+/g, ''))}/>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <p className={classes.Label}>Username</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="Username" value={userName} onChange={event => setUserName(event.target.value)}/>
                <div className={classes.TickIcon}>
                  {userName.length === 0 ? null :
                    (isUserNameAvailable ?
                      <AiFillCloseCircle color={'#ff3b3b'}/>
                      : <IoMdCheckmarkCircleOutline color={'rgb(113 191 63)'}/>)
                  }
                </div>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <p className={classes.Label}>Phone Number</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <PhoneInput
                  country={getCountry()}
                  countryCodeEditable={false}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  enableSearch={true}
                  disableSearchIcon={true}
                  inputClass={classes.PhoneInput}
                  buttonClass={classes.ButtonClass}
                  dropdownClass={classes.DropdownClass}
                  searchClass={classes.SearchClass}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={classes.InputsWrapper}>
          <div className={classes.Detail}>
            <p className={classes.Label}>Last Name</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="Last Name" value={lastName}
                       onChange={event => setLastName(event.target.value.replace(/[^a-zA-Z]+/g, ''))}/>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <p className={classes.Label}>Email Address</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <MdAlternateEmail size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="Email Address" value={email} onChange={event => setEmail(event.target.value)}/>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <p className={classes.Label}>QR Code</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="QR Code" value={qrLink} onChange={event => setQrLink(event.target.value)}/>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.MobileInputWrapper}>
          <div className={classes.Detail}>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="First name" value={firstName}
                       onChange={event => setFirstNme(event.target.value.replace(/[^a-zA-Z]+/g, ''))}/>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="Last Name" value={lastName}
                       onChange={event => setLastName(event.target.value.replace(/[^a-zA-Z]+/g, ''))}/>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="Username" value={userName} onChange={event => setUserName(event.target.value)}/>
                <div className={classes.TickIcon}>
                  {userName.length === 0 ? null :
                    (isUserNameAvailable ?
                      <AiFillCloseCircle color={'#ff3b3b'}/>
                      : <IoMdCheckmarkCircleOutline color={'rgb(113 191 63)'}/>)
                  }
                </div>
              </div>
            </div>
          </div>


          <div className={classes.Detail}>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <MdAlternateEmail size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="Email Address" value={email} onChange={event => setEmail(event.target.value)}/>
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <PhoneInput
                  country={getCountry()}
                  countryCodeEditable={false}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  enableSearch={true}
                  disableSearchIcon={true}
                  inputClass={classes.PhoneInput}
                  buttonClass={classes.ButtonClass}
                  dropdownClass={classes.DropdownClass}
                  searchClass={classes.SearchClass}
                />
              </div>
            </div>
          </div>

          <div className={classes.Detail}>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <div className={classes.IconContainer}>
                  <FiAnchor size={16} color="#8d8d8d"/>
                </div>
                <input placeholder="QR Code" value={qrLink} onChange={event => setQrLink(event.target.value)}/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.BottomButtonWrapper}>
        <button onClick={reset}>Reset</button>
        <button
          style={{
            background:
              `linear-gradient(91.3deg, 
                ${props.color} 
                1.61%, 
                ${createGradient(props.color, 50)} 
                100%)`
          }}
          onClick={onSaveAction}
        >
          {props.loading === true ? (
            <div className={classes.SpinnerContainer}>
              <ClipLoader loading={true} color={"white"} size={18}/>
            </div>
          ) : (
            <p>Create User</p>
          )}
        </button>
      </div>
    </section>
  )
};

const mapStateToProps = state => {
  return {
    loading: state.teams.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddNewTeamUser: (details, showToast,close) => dispatch(addTeamsUser(details, showToast,close)),
    onIsUserNameExist: username => dispatch(actions.isUserNameExist(username)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
