import React from "react";
import { usePWAInstall } from 'react-use-pwa-install'
import { MdOutlineFileDownload } from "react-icons/md";
import classes from "./InstallPWAButton.module.scss";

const InstallPWAButton = ({ small, styles }) => {
  const install = usePWAInstall();

  return (
    <>
      {install && (
        <div>
          {small ? (
            <button
              className={`${classes.pwaButton} ${
                classes.SmallButton
              }`}
              onClick={install}
            >
              <MdOutlineFileDownload className={classes.icon} />
            </button>
          ) : (
            <button className={styles} onClick={install}>
              <MdOutlineFileDownload className={classes.icon} />
              <p>Install as App</p>
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default InstallPWAButton;
