import classes from "./PrivacyModeChangeConfirm.module.scss";
import {MdOutlineClose} from "react-icons/md";
import {createGradient} from "../../../shared/Constants";
import React from "react";
import HighPrivacyMode from "../../../assets/images/highPrivacy.png"

const PrivacyModeChangeConfirm = (props) => {

  return (
    <div className={classes.HighPrivacyChangerPopup}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5"/>
        </div>
      </div>
      <p className={classes.ConfirmationText}>
        Do you want to {props.active ? "deactivate" : "activate"} High <br/>
        <span style={{color: props.color}}>Privacy Mode </span>?
      </p>
      <div className={classes.InnerContainer}>
        <img src={HighPrivacyMode} alt={"High Privacy Mode"}/>
        <p className={classes.InfoText}>
          Your sensitive information remains<br/> hidden from public view on shared links.<br/><br/>
          Contact details will only be accessible <br/>through card taps and QR scans.<br/><br/>
          Your data is safeguarded for increased <br/>privacy.
        </p>
      </div>
      <button
        style={{background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 50)} 100%)`}}
        className={classes.ConfirmButton}
        onClick={props.onClick}
      >
        Yes, {props.active ? "Deactivate" : "Activate"}
      </button>
    </div>
  )
};

export default PrivacyModeChangeConfirm;
