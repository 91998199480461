import classes from './Settings.module.scss';
import {Switch} from 'antd';
import React, {useEffect, useState} from "react";
import Backdrop from "../../../../../UI/Backdrop/Backdrop";
import ProfileSelfUpdateSettingConfirmation
  from './EnableProfileSelfUpdateConfirmation/EnableProfileSelfUpdateConfirmation';
import {connect} from "react-redux";
import {toast, ToastContainer} from "react-toastify";
import {AiOutlineCheck, AiOutlineClose} from "react-icons/ai";
import {createGradient} from "../../../../../../shared/Constants";
import {ClipLoader} from "react-spinners";
import * as actions from "../../../../../../store/actions";


const Settings = (props) => {

  const [currentSettingForUpdate, setCurrentSettingForUpdate] = useState(null)
  const [isSettingConfirmationModalOpen, setIsSettingConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [settings, setSettings] = useState({
  //   enableProfileSelfUpdate: true,
  // });

  const [settings, setSettings] = useState({
    enableProfileSelfUpdate: props?.data?.enableProfileSelfUpdate,
  });

  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style: {background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 35)} 100%)`}
    });
  };

  // /**
  //  *
  //  * @param {string} settingName
  //  * @param {boolean|string|number} value
  //  */
  // function onChangeSetting(settingName, value) {
  //   if (settingName === 'enableProfileSelfUpdate') {
  //     setCurrentSettingForUpdate(settingName);
  //     setIsSettingConfirmationModalOpen(true);
  //     setTempSettings({
  //       ...settings,
  //       [settingName]: value
  //     })
  //
  //     // setSettings({
  //     //     ...settings,
  //     //     [settingName]: value
  //     // })
  //   }
  // }


  useEffect(() => {
    props.getCompanySettings(showToast);
    //   console.log(settings);
    // const fetchData = async () => {
    //   const data = await props.onGetCompanyDetails();
    //   console.log("Settings fetched")
    //   if (data.setting) {
    //     console.log(data.setting, settingsUpdated)
    //     setSettings({
    //       ...settings,
    //       enableProfileSelfUpdate: data.setting.enableProfileSelfUpdate
    //     })
    //   }
    // }
    // if (settingsUpdated) {
    //   fetchData().then(() => {
    //     setSettingsUpdated(false)
    //   });
    // }
  }, [])
  return (
    <section className={classes.Settings}>
      {isSettingConfirmationModalOpen && (
        <Backdrop onClick={() => setIsSettingConfirmationModalOpen(false)}>
          <ProfileSelfUpdateSettingConfirmation
            enableProfileSelfUpdate={settings.enableProfileSelfUpdate}
            onConfirm={() => {
              props.setCompanySettings({
                enableProfileSelfUpdate: settings.enableProfileSelfUpdate
              }, showToast)
              setIsSettingConfirmationModalOpen(false)
            }}
            onClose={() => setIsSettingConfirmationModalOpen(false)}
          />
        </Backdrop>
      )}
      <p className={classes.Title}>Settings&ensp;{props.getDataLoading &&
        <div className={classes.SpinnerContainer}>
          <ClipLoader loading={true} color={"white"} size={24}/>
        </div>
      }</p>
      <div className={classes.MiddleContainer}>
        <ul className={classes.SettingsList}>
          {!props.getDataLoading && props?.data?.enableProfileSelfUpdate != null && <li>
            <div>
              <Switch checkedChildren={<AiOutlineCheck/>} unCheckedChildren={<AiOutlineClose/>}
                      disabled={props.setDataLoading}
                      loading={props.setDataLoading}
                      checked={props.data.enableProfileSelfUpdate}
                      onChange={(checked) => {
                        setSettings({
                          ...settings,
                          enableProfileSelfUpdate: checked
                        });
                        setIsSettingConfirmationModalOpen(true);
                      }}/>

            </div>
            <p>Allow users of the company to update their profile information</p>
          </li>}
        </ul>
      </div>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    data: state?.settings.data,
    setDataLoading: state?.settings.setDataLoading,
    getDataLoading: state?.settings.getDataLoading,
    color: state?.home.themeColor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanySettings: (showToast) => dispatch(actions.getCompanySettings(showToast)),
    setCompanySettings: (data, showToast) => dispatch(actions.setCompanySettings(data, showToast)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Settings);
