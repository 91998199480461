const initialState = {
  loading: false,
};


const reducer = (state = initialState, action) => {
  return state;
};

export default reducer;
