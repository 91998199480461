import * as actionTypes from '../actions/link/actionTypes';

const initialState = {
    link: [],
};

const getLink = (state, action) => {
    return {
        ...state,
        link: action.link,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LINK_BY_USERNAME:
            return getLink(state, action);
        default:
            return state;
    }
};

export default reducer;
