import React from 'react';
import classes from './Header.module.scss';
import {BiDotsVerticalRounded} from 'react-icons/bi';
import {HiMenuAlt2,HiOutlineChevronLeft } from 'react-icons/hi';
import {useNavigate} from "react-router-dom";

const Header = props => {
  const navigate = useNavigate();
  return (
    <div className={classes.Header}>
      <div>
        <HiOutlineChevronLeft onClick={()=>navigate('/home')}/>
        <div
          className={classes.SideButtonContainer}
          onClick={props.toggleDropdownVisibility}
        >
          <BiDotsVerticalRounded size={24} color="#BCBCBC" />
        </div>
      </div>
      <p className={classes.Title}>My Contacts</p>
      <div className={classes.SideButtonContainer} onClick={props.toggleMenu}>
        <HiMenuAlt2 size={24} color="#BCBCBC" />
      </div>
    </div>
  );
};

export default Header;
